export const NAV_ITEMS: Array<NavItem> = [
    {
      label: 'Home',
      href: './',
    },
    // {
    //     label: 'About Us',
    //     href: '/AboutUs',
    //   },
    
    // {
    //   label: 'Subscribe',
    //   href: './',
    // },
    // {
    //   label: 'Features',
    //   href: './',
    // },
   
    // {
    //   label: 'Bars',
    //   href: '/Bars',
    // },
    // {
    //   label: 'Reviews',
    //   href: '/Reviews',
    // },
    {
        label: 'Contact Us',
        href: '/ContactUs',
      },
      {
        label: 'Terms of Service',
        href: '/TermsPage',
      },
      {
        label: 'Privacy Policy',
        href: '/PrivacyPage',
      },
  ];