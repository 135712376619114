import {
  Box,
  Stack,
  Modal,
  Button,
  ModalContent,
  ModalHeader,
  Text,
  ModalBody,
  ModalCloseButton,
  ModalFooter,
  ModalOverlay,
  useDisclosure,
  Input,
  Textarea,
  FormControl,
  Checkbox,
  useToast,
  FormLabel,
  Select,
  Switch,
  Link,
} from '@chakra-ui/react';
import React, { useEffect, useState } from 'react';
import BorderButton from '../../../components/Website/Buttons/BorderButton';
import CustomHeading from '../../../components/Website/Headings/CustomHeading';
import CustomPara from '../../../components/Website/Paragraph/CustomPara';
import Event1 from '../../../assets/images/event/e1.jpg';
import { Link as ReactLink, useNavigate } from 'react-router-dom';
import MainDashboard from '../MainDashboard';
import { AiOutlineSearch, AiOutlinePlusCircle } from 'react-icons/ai';
import { Icon } from '@chakra-ui/icons';
import { GET, POST } from '../../../utilities/ApiProvider';
import moment from 'moment';
import { useSelector } from 'react-redux';

export default function Index() {
  const [events, setEvents] = useState([
    {
      live: [],
      upcomming: [],
      today: [],
    },
  ]);
  const [Hashtags, setHashtags] = useState([]);
  const [hashtagData, sethashtagData] = useState([]);
  const [dj, setDj] = useState([]);
  const toast = useToast();
  const [isLoading, setisLoading] = useState(false);

  const OverlayOne = () => (
    <ModalOverlay
      bg="blackAlpha.300"
      backdropFilter="blur(10px) hue-rotate(90deg)"
    />
  );

  const { isOpen, onOpen, onClose } = useDisclosure();
  const [overlay, setOverlay] = React.useState(<OverlayOne />);

  useEffect(() => {
    getHastags();
    getEvents();
    getDJ();
  }, []);

  const getEvents = async () => {
    var response = await GET('event', {
      authorization: `bearer ${user?.verificationToken}`
    });
    setEvents(response.data);
    console.log('ss', response);
  };
  const getHastags = async () => {
    var response = await GET('admin/hashtag?type=event');
    console.log('hashtags here', response)
    setHashtags(response.data);
  };
  const getDJ = async () => {
    var response = await GET('users/type/dj');

    setDj(response.data);
  };

  const [Fields, setFields] = useState({
    picture: {},
    repeat: false,
    venue: '450 Highland Ave, Salem, MA 1940',
    hashtags: [],
    type: 'web',
  });

  const submitForm = async () => {
    console.log(Fields);
    const form = document.getElementById('eventForm');
    const formData = new FormData(form);

    formData.append('hashtags', JSON.stringify(Fields.hashtags));


    try {
      var res = await POST('event', formData, {
        authorization: `bearer ${user?.verificationToken}`,
      });
      console.log(res);

      if (res.status == 200) {
        toast({
          position: 'bottom-left',
          isClosable: true,
          duration: 5000,
          status: 'success',
          description: 'Event created successfully',
        });
        onClose();
        getEvents();
      } else {
        toast({
          position: 'bottom-left',
          isClosable: true,
          duration: 5000,
          status: 'error',
          description: res?.data?.message,
        });
        setisLoading(false);
      }

      setisLoading(false);
    } catch (err) {
      console.log(err);
      toast({
        description: 'Something went wrong!',
        status: 'error',
        isClosable: true,
        position: 'bottom-left',
        duration: 2500,
      });
    }
  };

  const signupstyle = {
    outline: '1px solid #fff',
    py: '25px',
    bg: '#271623b5',
    color: '#fff',
  };

  const [localItem, setLocalItem] = useState(false);

  const navigate = useNavigate();

  const user = useSelector(state => state?.value);

  useEffect(() => {
    if (!user) {
      navigate('/dashboard/login');
    }
  }, [user]);

  const sethashtagDatas = e => {
    const find = Fields.hashtags.includes(e.target.value);
    if (find) {
      const data = Fields.hashtags.filter(item => {
        return item !== e.target.value;
      });
      setFields({ ...Fields, hashtags: data });
    } else {
      setFields({ ...Fields, hashtags: [...Fields.hashtags, e.target.value] });
    }
  };

  return (
    <>
      <MainDashboard>
        <Modal size={'3xl'} isCentered isOpen={isOpen} onClose={onClose}>
          {overlay}
          <ModalContent bg={'dashbg.100'}>
            <ModalHeader>
              <CustomPara marginBottom={'0'} fontSize={'20px'}>
                Add Events
              </CustomPara>
            </ModalHeader>
            <ModalCloseButton color={'#fff'} />
            <ModalBody>
              <form id="eventForm">
                <Stack gap={'4'}>
                  <Box
                    position={'relative'}
                    overflow={'hidden'}
                    w={'full'}
                    border={'1px dashed #fff'}
                    py={'8'}
                    textAlign={'center'}
                    borderRadius={'6'}
                  >
                    <Button>Upload a file</Button>
                    <Input
                      position={'absolute'}
                      left={'0'}
                      right={'0'}
                      bottom={'0'}
                      top={'0'}
                      h={'100%'}
                      cursor={'pointer'}
                      color={'white'}
                      py={'34px'}
                      type={'file'}
                      name={'picture'}
                      onChange={e => {
                        setFields({
                          ...Fields,
                          picture: e.target.files[0],
                        });
                      }}
                    />
                  </Box>
                  <Input
                    sx={signupstyle}
                    placeholder={'Title'}
                    name="name"
                    type="Name"
                    _placeholder={{ color: '#fff' }}
                    value={Fields.title}
                    onChange={e => {
                      setFields({
                        ...Fields,
                        name: e.target.value,
                      });
                    }}
                  />
                  <Textarea
                    sx={signupstyle}
                    placeholder={'Description'}
                    type="Name"
                    name="description"
                    _placeholder={{ color: '#fff' }}
                    value={Fields.description}
                    onChange={e => {
                      setFields({
                        ...Fields,
                        description: e.target.value,
                      });
                    }}
                  ></Textarea>
                  <Stack
                    gap={'6'}
                    direction={'row'}
                    justifyContent={'space-between'}
                  >
                    <Box w={'full'} position={'relative'}>
                      <FormLabel
                        fontWeight={'600'}
                        color={'#fff'}
                        zIndex={'1'}
                        fontSize={'13px'}
                      >
                        Date
                      </FormLabel>
                      <Input
                        type={'date'}
                        bg={'#212121'}
                        pt={'7'}
                        pb={'6'}
                        w={'full'}
                        name="date"
                        color={'#fff'}
                        outline={'1px solid #fff'}
                        onChange={e => {
                          setFields({
                            ...Fields,
                            date: e.target.value,
                          });
                        }}
                      />
                    </Box>
                    <Box w={'full'} position={'relative'}>
                      <FormLabel
                        fontWeight={'600'}
                        color={'#fff'}
                        zIndex={'1'}
                        fontSize={'13px'}
                      >
                        End Date
                      </FormLabel>
                      <Input
                        type={'date'}
                        bg={'#212121'}
                        pt={'7'}
                        pb={'6'}
                        w={'full'}
                        name="enddate"
                        color={'#fff'}
                        outline={'1px solid #fff'}
                        onChange={e => {
                          setFields({
                            ...Fields,
                            enddate: e.target.value,
                          });
                        }}
                      />
                    </Box>
                  </Stack>
                  <Stack
                    gap={'6'}
                    direction={'row'}
                    justifyContent={'space-between'}
                    alignItems={'center'}
                  >
                    <Box w={'full'} position={'relative'}>
                      <Input
                        type={'number'}
                        bg={'#212121'}
                        pt={'7'}
                        pb={'6'}
                        w={'full'}
                        name="price"
                        color={'#fff'}
                        outline={'1px solid #fff'}
                        placeholder={'Price'}
                        onChange={e => {
                          setFields({
                            ...Fields,
                            price: e.target.value,
                          });
                        }}
                      />
                    </Box>
                    <Box w={'full'} position={'relative'}>
                      <Input
                        type={'number'}
                        bg={'#212121'}
                        pt={'7'}
                        pb={'6'}
                        w={'full'}
                        name="stock"
                        color={'#fff'}
                        outline={'1px solid #fff'}
                        placeholder={'Stock'}
                        onChange={e => {
                          setFields({
                            ...Fields,
                            stock: e.target.value,
                          });
                        }}
                      />
                    </Box>
                  </Stack>
                  <Stack
                    gap={'6'}
                    direction={'row'}
                    justifyContent={'space-between'}
                    alignItems={'center'}
                  >
                    <Box w={'full'} position={'relative'}>
                      <Input
                        type={'text'}
                        bg={'#212121'}
                        pt={'7'}
                        pb={'6'}
                        name="venue"
                        w={'full'}
                        color={'#fff'}
                        outline={'1px solid #fff'}
                        placeholder={'Venue'}
                        onChange={e => {
                          setFields({
                            ...Fields,
                            venue: e.target.value,
                          });
                        }}
                      />
                    </Box>
                  </Stack>

                  <Box
                    display={'flex'}
                    justifyContent={'space-between'}
                    alignItems={'center'}
                    gap={'4'}
                  >
                    <CustomHeading
                      fontSize={'22px'}
                      color={'#fff'}
                      textAlign={'left'}
                    >
                      Hashtags
                    </CustomHeading>
                    
                    {/* 
                     */}
                  </Box>
                  <Stack
                    direction={'row'}
                    gap={'3'}
                    spacing={'0'}
                    flexWrap={'wrap'}
                    justifyContent={'space-between'}
                    color={'#fff'}
                  >
                    {Hashtags?.length > 0 &&
                      Hashtags?.map(e => {
                        return (
                          <Checkbox
                            border={'1px solid #fff'}
                            position={'relative'}
                            px={'1'}
                            py={'1'}
                            borderRadius={'6'}
                            className="chckbox"
                            value={e._id}
                            onChange={sethashtagDatas}
                            w={'160px'}
                          >
                            <Stack direction={'row'}>
                              <Icon
                                color={'white'}
                                fontSize={'20px'}
                                as={AiOutlinePlusCircle}
                              />
                              <Text
                                fontSize={'13px'}
                              >
                                {e.name}</Text>
                            </Stack>
                          </Checkbox>
                        );
                      })}
                  </Stack>
                </Stack>
              </form>
            </ModalBody>
            <ModalFooter>
              <Stack direction={'row'} w={'full'} justifyContent={'center'}>
                <Button
                  onClick={() => submitForm()}
                  bg={'pink.500'}
                  color={'#fff'}
                  px={'14'}
                >
                  Post
                </Button>
                <Button onClick={onClose}>Discard</Button>
              </Stack>
            </ModalFooter>
          </ModalContent>
        </Modal>
        <Stack p={'4'} gap={'8'}>
          <Stack direction={'row'} justifyContent={'space-between'}>
            <Box display={'flex'} alignItems={'center'} gap={'4'}>
              <CustomHeading
                fontSize={'30px'}
                color={'#fff'}
                textAlign={'left'}
              >
                Live Events
              </CustomHeading>
            </Box>
            \{/*  ADD EVENT HERE */}
            <Box>
              <Button
                bg={'transparent'}
                textAlign={'center'}
                margin={'auto'}
                py={'10px'}
                px={'8'}
                lineHeight={'inherit'}
                border={'1px solid #fff'}
                borderRadius={'6px'}
                color={'#fff'}
                _hover={{
                  color: 'primaryText.200',
                }}
                onClick={() => {
                  setOverlay(<OverlayOne />);
                  onOpen();
                }}
              >
                Add New Events
              </Button>
            </Box>
          </Stack>

          <Stack
            flexWrap={'wrap'}
            direction={{ base: 'column', lg: 'row' }}
            alignItems={'center'}
            gap={'4'}
          >
            {events &&
              events[0].live.map(e => {
                return (
                  <Link state={e} as={ReactLink} to={'/dashboard/singleevent'}>
                    <Box backgroundImage={Event1} w={'346px'} py={'4'}>
                      <Box bg={'pHeading.100'} w={'100px'} py={'1'} mb={'6'}>
                        <CustomPara
                          bgColor={'#d53f8c'}
                          marginBottom={'0'}
                          textAlign={'center'}
                        >
                          Today
                        </CustomPara>
                      </Box>
                      <Stack px={'4'} mb={'24'}>
                        <CustomHeading
                          color={'#fff'}
                          fontSize={'25px'}
                          textAlign={'left'}
                          mb={'0'}
                        >
                          {e.name}
                        </CustomHeading>
                        <CustomPara fontSize={'14px'}>
                          {e.description}
                        </CustomPara>
                      </Stack>
                      <Stack
                        px={'4'}
                        direction={'row'}
                        justifyContent={'space-between'}
                        alignItems={'center'}
                      >
                        <Box>
                          <CustomHeading
                            mb={'0'}
                            color={'#fff'}
                            fontSize={'20px'}
                            textAlign={'left'}
                          >
                            Sold Out
                          </CustomHeading>
                        </Box>
                        <Box bg={'#ff4764'} px={'2'}>
                          <CustomPara marginBottom={'0'}>LIVE</CustomPara>
                        </Box>
                      </Stack>
                    </Box>
                  </Link>
                );
              })}
          </Stack>

          {/* Up comming Event */}

          <Stack>
            <Box>
              <CustomHeading
                fontSize={'30px'}
                color={'#fff'}
                textAlign={'left'}
              >
                Today's Events
              </CustomHeading>
            </Box>
            <Stack
              flexWrap={'wrap'}
              direction={{ base: 'column', lg: 'row' }}
              alignItems={'center'}
              gap={'4'}
            >
              {events &&
                events[0].today.map(e => {
                  return (
                    <Box
                      backgroundImage={Event1}
                      rounded={'10px'}
                      w={'346px'}
                      py={'4'}
                    >
                      <Box
                        bg={'pink.500'}
                        roundedRight={'5px'}
                        p-5
                        w={'100px'}
                        py={'1'}
                        mb={'6'}
                      >
                        <CustomPara marginBottom={'0'} textAlign={'center'}>
                          Today
                        </CustomPara>
                      </Box>
                      <Stack px={'4'} mb={'24'}>
                        <CustomHeading
                          color={'#fff'}
                          fontSize={'25px'}
                          textAlign={'left'}
                          mb={'0'}
                        >
                          {e.name}
                        </CustomHeading>
                        <CustomPara fontSize={'14px'}>
                          {e.description}
                        </CustomPara>
                      </Stack>
                      <Stack
                        px={'4'}
                        direction={'row'}
                        justifyContent={'space-between'}
                        alignItems={'center'}
                      >
                        <Box>
                          <CustomHeading
                            mb={'0'}
                            color={'#fff'}
                            fontSize={'20px'}
                            textAlign={'left'}
                          >
                            Sold Out
                          </CustomHeading>
                        </Box>
                        <Box bg={'#ff4764'} px={'2'}>
                          <CustomPara marginBottom={'0'}>LIVE</CustomPara>
                        </Box>
                      </Stack>
                    </Box>
                  );
                })}
            </Stack>
          </Stack>

          <Stack>
            <Box>
              <CustomHeading
                fontSize={'30px'}
                color={'#fff'}
                textAlign={'left'}
              >
                Upcomming Events
              </CustomHeading>
            </Box>
            <Stack
              flexWrap={'wrap'}
              direction={{ base: 'column', lg: 'row' }}
              alignItems={'center'}
              gap={'4'}
            >
              {events &&
                events[0].upcomming.map(e => {
                  return (
                    <Box backgroundImage={Event1} w={'346px'} py={'4'}>
                      <Box
                        bg={'pink.500'}
                        roundedRight={'5px'}
                        p-5
                        w={'100px'}
                        py={'1'}
                        mb={'6'}
                      >
                        <CustomPara marginBottom={'0'} textAlign={'center'}>
                          Today
                        </CustomPara>
                      </Box>
                      <Stack px={'4'} mb={'24'}>
                        <CustomHeading
                          color={'#fff'}
                          fontSize={'25px'}
                          textAlign={'left'}
                          mb={'0'}
                        >
                          {e.name}
                        </CustomHeading>
                        <CustomPara fontSize={'14px'}>
                          {e.description}
                        </CustomPara>
                      </Stack>
                      <Stack
                        px={'4'}
                        direction={'row'}
                        justifyContent={'space-between'}
                        alignItems={'center'}
                      >
                        <Box>
                          <CustomHeading
                            mb={'0'}
                            color={'#fff'}
                            fontSize={'20px'}
                            textAlign={'left'}
                          >
                            Sold Out
                          </CustomHeading>
                        </Box>
                        <Box bg={'#ff4764'} px={'2'}>
                          <CustomPara marginBottom={'0'}>LIVE</CustomPara>
                        </Box>
                      </Stack>
                    </Box>
                  );
                })}
            </Stack>
          </Stack>
        </Stack>
      </MainDashboard>
    </>
  );
}
