import { Box, FormControl, Text, FormLabel, Input, Stack, filter, Button } from '@chakra-ui/react';
import React from 'react';
import BorderButton from '../../Website/Buttons/BorderButton';
import CustomHeading from '../../Website/Headings/CustomHeading';
import NightlyReportList from '../../Dashboard/Order/NightlyReportList'
import { Link, useLocation } from 'react-router-dom';

export default function NightlyReports({ filterData, reportUrl }) {

  return (
    <>
      <Stack>
        <Stack
          mb={'4'}
          alignItems={'center'}
          justifyContent={'space-between'}
          direction={'row'}
          gap={'6'}
        >
          <CustomHeading
            mb={'0'}
            textAlign={'left'}
            fontSize={'20px'}
            color={'#fff'}
          >
            Nightly Overview Reports
          </CustomHeading>
        </Stack>
        <Stack h={'420px'} overflow={'scroll'} bg={'dashbg.100'} p={'4'}>
          {
            reportUrl?.length > 0 ? reportUrl?.map((item) => {
              return (
                <NightlyReportList key={item._id} item={item} />

              )
            }) : <Text>No Data Found</Text>
          }
          {/* <NightlyReportList/>

          <NightlyReportList/>
          <NightlyReportList/>
          <NightlyReportList/>
          <NightlyReportList/>
          <NightlyReportList/> */}
        </Stack>
      </Stack>
    </>
  );
}
