import React from 'react';
import { Divider, Link, ListItem, Stack, Text, UnorderedList } from '@chakra-ui/react';
import { Link as ReactLink } from 'react-router-dom';
import { Icon } from '@chakra-ui/icons';
import { AiOutlineGoogle, AiFillFacebook, AiOutlineTwitter, } from 'react-icons/ai';

/* TODO:  Actually impliment SSO */

export const SocialSignIn =()=> {

	/* TODO: break these out, use current values as defaults */
	const socialink = {
		bg: '#fff', borderRadius: '6px',
		w: '50px', h: '50px', display: 'flex',
		alignItems: 'center', justifyContent: 'center'
	};
	const socialicn = { fontSize: '20px', color: 'dashbg.100' };

	return (
		<>
			<Stack>
				<Stack mb={'6'} alignItems={'center'} direction={'row'}>
					<Divider />
					<Text textAlign={'center'} w={'400px'} color={'#fff'}>Or continue with</Text>
					<Divider />
				</Stack>
				<UnorderedList justifyContent={'center'} listStyleType={'none'} display={'flex'} gap={'4'}>
					{/* <!-- GOOGLE --> */}
					<ListItem>
						<Link sx={socialink} as={ReactLink} to={'/'} _hover={{ bgColor: '#bc0c89' }}>
							<Icon sx={socialicn} as={AiOutlineGoogle} />
						</Link>
					</ListItem>
					{/* <!-- FACEBOOK --> */}
					<ListItem>
						<Link sx={socialink} as={ReactLink} to={'/'} _hover={{ bgColor: '#bc0c89' }}>
							<Icon sx={socialicn} as={AiFillFacebook} />
						</Link>
					</ListItem>
					{/* <!-- TWITTER --> */}
					<ListItem>
						<Link sx={socialink} as={ReactLink} to={'/'} _hover={{ bgColor: '#bc0c89' }}>
							<Icon sx={socialicn} as={AiOutlineTwitter} />
						</Link>
					</ListItem>
				</UnorderedList>
			</Stack>
		</>
	);
}
