import React from 'react';
import { useEffect } from 'react';
import TermsSummaryComponent from '../../components/Website/Agreements/TermsOfService';
import InerBannner from '../../components/Website/Banners/InerBannner';
import Testimonail from '../../components/Website/Testimonials/Testimonials';
import CustomPara from '../../components/Website/Paragraph/CustomPara';

export default function TermsPage() {

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  }, [])

  return (
    <>
      <InerBannner simpleHeading={'Terms of Service'} bannerMessage={''} />
      <TermsSummaryComponent />
      <Testimonail />
    </>
  );
}