import { Box, Stack } from '@chakra-ui/react';
import React, { useEffect, useState } from 'react';
import MainDashboard from '../MainDashboard';
import CustomHeading from '../../../components/Website/Headings/CustomHeading';
import OrderBox from '../../../components/Dashboard/Order/OrderBox';
import OrderSalesCharts from '../../../components/Dashboard/Order/OrderSalesCharts';
import NightlyReports from '../../../components/Dashboard/Order/NightlyReports';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { GET } from '../../../utilities/ApiProvider';
export default function Index() {
  // console.log(JSON.parse(localStorage.getItem('user')));
  const navigate = useNavigate();
  const [data, setData] = useState([]);
  const [arrayData, setArrayData] = useState([]);
  const [filterData, setFilterData] = useState([]);
  const [graph, setGraph] = useState([]);
  const [label, setLabel] = useState([]);
  const [salesData, setSalesData] = useState([]);
  const selector = useSelector(state => state);

  const user = useSelector(state => state?.value);

  const getData = async () => {
    const res = await GET('bar/home', {
      authorization: `bearer ${user?.verificationToken}`,
    });
    console.log(res)
    setData(res?.data);
    setGraph(res?.data?.graph);
    setSalesData(res?.data?.salesData);
  };

  // useEffect(() => {
  //   let localUser = localStorage.getItem('user');
  //   console.log(localUser)
  //   if (user || localUser) {
  //     getData();
  //   } else {
  //     navigate('/dashboard/login');
  //   }
  // }, [user]);

  useEffect(() => {
    let existingParsedUser = JSON.parse(localStorage.getItem("userCreds"));
    console.log(existingParsedUser)
    if (existingParsedUser) {
      if (existingParsedUser?.barInfo !== null || existingParsedUser?.barInfo !== undefined) {
        navigate('/dashboard')
        getData();
      } else if (existingParsedUser?.barInfo === null || existingParsedUser?.barInfo === undefined) {
        navigate('/dashboard/Plan')
      } else {
        getData();
      }
    }
  }, [])

  const modifyData = async () => {
    const modifiedData = salesData?.map(item => {
      const createdAt = item?.createdAt;
      const dateOnly = new Date(createdAt).toISOString().split('T')[0];

      return {
        _id: item?._id,
        orderNo: item?.orderNo,
        createdAt: dateOnly,
        amount: item?.amount,
      };
    });
    setFilterData(modifiedData);
  };

  useEffect(() => {
    modifyData();
  }, [salesData]);



  useEffect(() => {
    const keysArray = data?.graph?.map(obj => Object.keys(obj)[0]);
    const valuesArray = data?.graph?.map(obj => Object.values(obj)[0]);
    setArrayData(valuesArray);
    setLabel(keysArray);
  }, [graph]);




  return (
    <>
      <MainDashboard title={'Home'}>
        <Stack p={'4'} gap={'8'}>
          <Stack>
            <CustomHeading textAlign={'left'} fontSize={'30px'} color={'#fff'}>
              Orders
            </CustomHeading>
          </Stack>
          <Stack
            flexWrap={'wrap'}
            spacing={'0'}
            direction={'row'}
            // gap={{ '2xl': '6', md: '4',lg:"6" }}
            gap={"20px"}
          >
            <OrderBox title={'Orders'} data={data?.orders} check={'number'} />
            <OrderBox title={'Events'} data={data?.events} check={'number'} />
            <OrderBox
              title={'Menu Sales'}
              data={data?.menuSales}
              check={'number'}
            />
            <OrderBox
              title={'Attendance'}
              data={data?.attendence}
              check={'number'}
            />
            <OrderBox
              title={'Average Drink Rating'}
              data={data?.averageDrinkRating}
            />
            <OrderBox
              title={'Average Event Rating'}
              data={data?.averageEventRating}
            />
          </Stack>
          <Stack
            direction={{ base: "column", lg: "row" }}
            gap={'4'}
            display={{ base: "none", lg: "block" }}
          >
            <Stack w={{ '2xl': '50%', lg: '100%' }}>
              <NightlyReports filterData={filterData} reportUrl={data?.reports} />
            </Stack>
            <Stack w={'100%'}>
              <OrderSalesCharts datas={arrayData} labels={label} />
            </Stack>
          </Stack>
        </Stack>
      </MainDashboard>
    </>
  );
}
