import {
  Box,
  Flex,
  Image,
  Link,
  ListItem,
  Stack,
  UnorderedList,
} from '@chakra-ui/react';
import { Icon } from '@chakra-ui/icons';
import { AiFillStar } from 'react-icons/ai';
import React, { useState } from 'react';
import { Text } from '@chakra-ui/react';
import MainDashboard from '../MainDashboard';
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import { Pie } from 'react-chartjs-2';
import OrderBox from '../../../components/Dashboard/Order/OrderBox';
import CustomHeading from '../../../components/Website/Headings/CustomHeading';
import M1 from '../../../assets/images/menu/m1.jpg';
import BorderButton from '../../../components/Website/Buttons/BorderButton';
import Event1 from '../../../assets/images/event/e1.jpg';
import CustomPara from '../../../components/Website/Paragraph/CustomPara';
import { imgUrl } from '../../../utilities/Config';
import { useNavigate } from 'react-router-dom';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { GET } from '../../../utilities/ApiProvider';
ChartJS.register(ArcElement, Tooltip, Legend);

export default function Index() {
  const [localItem, setLocalItem] = useState(false);
  const [user, setUser] = useState({});
  const [sellingEvent, setSellingEvent] = useState([]);
  const [sellingChart, setSellingChart] = useState([]);
  const [datas, setDatas] = useState({});
  const navigate = useNavigate();
  const [labels, setLabels] = useState({
    label: [],
    backgroundColor: [],
    percentage: [],
  });

  const [labels2, setLabels2] = useState({
    label: [],
    backgroundColor: [],
    percentage: [],
  });
  const [labels3, setLabels3] = useState({
    label: [],
    backgroundColor: [],
    percentage: [],
  });

  const selector = useSelector(state => state);

  const getData = async () => {
    const res = await GET('bar/analytics', {
      authorization: `bearer ${user?.verificationToken}`,
    });
    setDatas(res?.data);
    setSellingEvent(res?.data?.bestSellingEvents);
    setSellingChart(res?.data?.bestSellingMenuPieChart);
  };

  useEffect(() => {
    if (selector) {
      setUser(selector?.value);
    }
  }, [selector]);

  useEffect(() => {
    if (user) {
      getData();
    } else {
      navigate('/dashboard');
    }
  }, [user]);

  useEffect(() => {
    const chart1 = sellingChart?.map(value => {
      return value?.label;
    });

    const chart2 = sellingChart?.map(value => {
      return value?.backgroundColor;
    });
    const chart3 = sellingChart?.map(value => {
      return Math.round(value?.percentage);
    });

    setLabels({
      ...labels,
      label: chart1,
      backgroundColor: chart2,
      percentage: chart3,
    });
  }, [sellingChart]);

  // useEffect(() => {
  //   const chart1 = datas?.demoGraphics?.map(value => {
  //     return value?.label;
  //   });

  //   const chart2 = datas?.demoGraphics?.map(value => {
  //     return value?.color;
  //   });
  //   const chart3 = datas?.demoGraphics?.map(value => {
  //     return Math.round(value?.percentage);
  //   });

  //   setLabels2({
  //     ...labels2,
  //     label: chart1,
  //     backgroundColor: chart2,
  //     percentage: chart3,
  //   });
  // }, [datas]);

  useEffect(() => {
    const chart1 = datas?.demoGraphics?.map(value => {
      return value?.label;
    });

    const chart2 = datas?.demoGraphics?.map(value => {
      return value?.color;
    });
    const chart3 = datas?.demoGraphics?.map(value => {
      return Math.round(value?.percentage);
    });

    setLabels2({
      ...labels2,
      label: chart1,
      backgroundColor: chart2,
      percentage: chart3,
    });
  }, [datas]);

  useEffect(() => {
    const chart1 = datas?.userAgeDistribution?.map(value => {
      return value?.ageGroup;
    });

    const chart2 = datas?.userAgeDistribution?.map(value => {
      return value?.color;
    });
    const chart3 = datas?.userAgeDistribution?.map(value => {
      return Math.round(value?.percentage);
    });

    setLabels3({
      ...labels3,
      label: chart1,
      backgroundColor: chart2,
      percentage: chart3,
    });
  }, [datas]);

  const options = {
    plugins: {
      legend: {
        position: 'right',
        rtl: true,
        labels: {
          usePointStyle: true,
          pointStyle: 'circle',
          padding: 20,
        },
      },
    },
  };

  const data = {
    labels: labels?.label ?? [
      'Red',
      'Blue',
      'Yellow',
      'Green',
      'Purple',
      'Orange',
    ],
    datasets: [
      {
        label: '# of Votes',
        data: labels.percentage ?? [12, 19, 3, 5, 2, 3],
        backgroundColor: labels?.backgroundColor ?? [
          '#B94F65',
          '#319FEA',
          '#776438',
          '#448484',
          '#694AA5',
          '#A56D36',
        ],
        borderColor: [
          'rgba(255, 99, 132, 1)',
          'rgba(54, 162, 235, 1)',
          'rgba(255, 206, 86, 1)',
          'rgba(75, 192, 192, 1)',
          'rgba(153, 102, 255, 1)',
          'rgba(255, 159, 64, 1)',
        ],
        borderWidth: 1,
      },
    ],
  };

  const data2 = {
    labels: labels2?.label ?? [
      'Red',
      'Blue',
      'Yellow',
      'Green',
      'Purple',
      'Orange',
    ],
    datasets: [
      {
        label: '# of Votes',
        data: labels2.percentage ?? [12, 19, 3, 5, 2, 3],
        backgroundColor: labels2?.backgroundColor ?? [
          '#B94F65',
          '#319FEA',
          '#776438',
          '#448484',
          '#694AA5',
          '#A56D36',
        ],
        borderColor: [
          'rgba(255, 99, 132, 1)',
          'rgba(54, 162, 235, 1)',
          'rgba(255, 206, 86, 1)',
          'rgba(75, 192, 192, 1)',
          'rgba(153, 102, 255, 1)',
          'rgba(255, 159, 64, 1)',
        ],
        borderWidth: 1,
      },
    ],
  };

  const data3 = {
    labels: labels3?.label ?? [
      'Red',
      'Blue',
      'Yellow',
      'Green',
      'Purple',
      'Orange',
    ],
    datasets: [
      {
        label: '# of Votes',
        data: labels3?.percentage ?? [12, 19, 3, 5, 2, 3],
        backgroundColor: labels3?.backgroundColor ?? [
          '#B94F65',
          '#319FEA',
          '#776438',
          '#448484',
          '#694AA5',
          '#A56D36',
        ],
        borderColor: [
          'rgba(255, 99, 132, 1)',
          'rgba(54, 162, 235, 1)',
          'rgba(255, 206, 86, 1)',
          'rgba(75, 192, 192, 1)',
          'rgba(153, 102, 255, 1)',
          'rgba(255, 159, 64, 1)',
        ],
        borderWidth: 1,
      },
    ],
  };


  return (
    <>
      <MainDashboard>
        <Stack p={'4'} gap={'6'}>
          <Stack>
            <CustomHeading
              textAlign={'left'}
              fontSize={'30px'}
              color={'pink.500'}
            >
              Overview
            </CustomHeading>
          </Stack>
          <Stack
            pb={'6'}
            spacing={'0'}
            flexWrap={'wrap'}
            direction={'row'}
            gap={'6'}
          >
            <OrderBox
              data={datas?.averagingEventRatingsCount}
              title={'Averaging Event Ratings Count'}
            />
            <OrderBox
              data={datas?.eventAttendanceCount}
              check={'number'}
              title={'Event Attendance Count'}
            />
            <OrderBox
              data={datas?.totalMenuSalesCount}
              check={'number'}
              title={'Total Menu Sales Count'}
            />
            <OrderBox
              data={datas?.totalTicketCounts}
              check={'number'}
              title={'Total Ticket Counts'}
            />
          </Stack>
          <Stack gap={'6'} direction={{ base: 'column', lg: 'row' }}>
            <Stack w={{ base: '100%', lg: '60%' }} gap={'6'}>
              <Stack>
                <Box>
                  <CustomHeading
                    textAlign={{ base: 'center', lg: 'left' }}
                    fontSize={'25px'}
                    color={'pink.500'}
                  >
                    Events Analytics
                  </CustomHeading>
                </Box>
                <Stack
                  gap={'14'}
                  direction={{ base: 'column', lg: 'row' }}
                  bg={'dashbg.100'}
                  px={'8'}
                  py={'4'}
                  alignItems={'center'}
                >
                  <Stack w={{ base: '100%', lg: '50%' }}>
                    <CustomHeading
                      textAlign={{ base: 'center', lg: 'left' }}
                      fontSize={'20px'}
                      color={'pink.500'}
                    >
                      Best Selling Menu
                    </CustomHeading>
                    <Pie options={options} data={data} />
                  </Stack>
                  <Stack
                    w={{ base: '100%', lg: '50%' }}
                    alignItems={'center'}
                    gap={'2'}
                  >
                    {datas?.mostPopularMenuCategories?.length>0? datas?.mostPopularMenuCategories?.map(value => {
                      return (
                        <Box key={value._id}>
                          <CustomHeading
                            textAlign={'left'}
                            fontSize={{ base: '17px', lg: '20px' }}
                            color={'pink.500'}
                          >
                            Most Popular Menu Category
                          </CustomHeading>
                          <Flex gap={'6'} alignItems={'center'}>
                            <Image src={imgUrl + '/' + value?.category_image} />
                            <CustomHeading fontSize={'19px'} textAlign={'left'}>
                              {value?.name}
                            </CustomHeading>
                          </Flex>
                        </Box>
                      );
                    }):<Text color={"white"} fontSize={"17px"}>No Data Found</Text>}
                  </Stack>
                </Stack>
              </Stack>
              <Stack>
                <Stack
                  mb={'4'}
                  alignItems={'center'}
                  justifyContent={'space-between'}
                  direction={{ base: 'column', lg: 'row' }}
                >
                  <CustomHeading
                    mb={'0'}
                    textAlign={{ base: 'center', lg: 'left' }}
                    fontSize={'23px'}
                    color={'#fff'}
                  >
                    Demographic
                  </CustomHeading>
                  <Box>
                    <BorderButton
                      width={{ base: '100%', lg: '40%' }}
                      Url={'/'}
                      Btnctn={'View Past Report'}
                    />
                  </Box>
                </Stack>
                <Stack
                  gap={'14'}
                  direction={{ base: 'column', lg: 'row' }}
                  bg={'dashbg.100'}
                  px={'8'}
                  py={'4'}
                  alignItems={'center'}
                >
                  <Stack w={{ base: '100%', lg: 'row' }}>
                    <CustomHeading
                      textAlign={{ base: 'center', lg: 'left' }}
                      fontSize={'20px'}
                      color={'pink.500'}
                    >
                      Summary:
                    </CustomHeading>
                    <Pie options={options} data={data2} />
                  </Stack>
                  <Stack w={{ base: '100%', lg: 'row' }}>
                    <Pie options={options} data={data3} />
                  </Stack>
                </Stack>
              </Stack>
            </Stack>
            <Stack w={{ base: '100%', lg: '40%' }}>
              <Box>
                <CustomHeading
                  textAlign={'left'}
                  fontSize={'25px'}
                  color={'pink.500'}
                >
                  Events Analytics
                </CustomHeading>
              </Box>
              <Stack p={'4'} bg={'dashbg.100'}>
                <Box
                  justifyContent={'space-between'}
                  alignItems={'center'}
                  display={'flex'}
                >
                  <CustomHeading
                    textAlign={'left'}
                    mb={'0'}
                    fontSize={'20px'}
                    color={'pink.500'}
                  >
                    Events
                  </CustomHeading>
                  <Link color={'pink.500'} to={'/'}>
                    View All
                  </Link>
                </Box>
                <UnorderedList>
                  {sellingEvent?.length > 0 ? (
                    sellingEvent?.map(value => {
                      return (
                        <ListItem key={value._id}>
                          <Stack
                            pb={'3'}
                            mt={'4'}
                            borderBottom={'1px solid #fff'}
                            justifyContent={'space-between'}
                            alignItems={'center'}
                            direction={'row'}
                          >
                            <Box
                              gap={'4'}
                              alignItems={'center'}
                              display={'flex'}
                            >
                              <Image
                                w={'100px'}
                                src={imgUrl + value?.picture}
                              />
                              <Box>
                                <CustomHeading
                                  textAlign={'left'}
                                  color={'pink.500'}
                                  fontSize={{ base: '18px', lg: '25px' }}
                                >
                                  {value?.name}
                                </CustomHeading>
                                <CustomPara color={'pHeading.100'}>
                                  Total Attendance : {value?.totalAttendance}
                                </CustomPara>
                              </Box>
                            </Box>
                            <Box alignItems={'center'} display={'flex'}>
                              <CustomPara fontSize={'19px'}>
                                {value?.rating}
                              </CustomPara>
                              <UnorderedList display={'flex'}>
                                <ListItem>
                                  <Icon
                                    color={'pink.500'}
                                    fontSize={'16px'}
                                    as={AiFillStar}
                                  />
                                </ListItem>
                                <ListItem>
                                  <Icon
                                    color={'pink.500'}
                                    fontSize={'16px'}
                                    as={AiFillStar}
                                  />
                                </ListItem>
                                <ListItem>
                                  <Icon
                                    color={'pink.500'}
                                    fontSize={'16px'}
                                    as={AiFillStar}
                                  />
                                </ListItem>
                                <ListItem>
                                  <Icon
                                    color={'#ffee37'}
                                    fontSize={'16px'}
                                    as={AiFillStar}
                                  />
                                </ListItem>
                              </UnorderedList>
                            </Box>
                          </Stack>
                        </ListItem>
                      );
                    })
                  ) : (
                    <Text>No Data Found</Text>
                  )}
                </UnorderedList>
              </Stack>
            </Stack>
          </Stack>
        </Stack>
      </MainDashboard>
    </>
  );
}
