import {
  Box,
  Container,
  Flex,
  Image,
  Stack,
  ListItem,
  UnorderedList,
  useBreakpointValue
} from '@chakra-ui/react';
import React from 'react';
import CustomHeading from '../Headings/CustomHeading';
import CustomPara from '../Paragraph/CustomPara';
import profile from '../../../assets/images/shorts/kierra.png';
import profile2 from '../../../assets/images/shorts/donald.png';
import Qta from '../../../assets/images/shorts/quta.png';
import { AiFillStar } from 'react-icons/ai';
import { Icon } from '@chakra-ui/icons';
import BackgroundImg from '../../../assets/images/Banner/background3.png';

export default function Testimonials() {
  // Use useBreakpointValue to adjust layout for smaller screens
  const flexDirection = useBreakpointValue({ base: 'column', md: 'row' });
  const stackSpacing = useBreakpointValue({ base: '6', md: '12' });

  return (
    <>
      <Stack
        py={'20'}
        bgImg={BackgroundImg}
        bgSize={'cover'}
        bgRepeat={'no-repeat'}
        mt={'0 !important'}
      >

        <Container maxW={'6xl'} p={{ base: '15px !important', '2xl': 0 }}>
          <Stack>
            <Box mb={'10'}>
              <CustomHeading
                color={'#fff'}
                textShadow="0 0 4px #fff"
                fontSize={{
                  base: '36px',
                  md: '50px',
                  xl: '45px',
                  '2xl': '54px',
                }}
              >
                <b>What Our Users Say</b>
              </CustomHeading>
              <CustomPara textAlign={'center'}>
                <b>Discover what our users are saying about Night District</b>
              </CustomPara>
            </Box>
            <Stack direction={flexDirection} gap={stackSpacing}>
              <Box
                height={375}
                backdropFilter={'blur(12px)'}
                borderRadius={'12'}
                py={'10'}
                px={'12'}
                mb={'6'}
                borderColor={'rgba(220, 11, 155, 0.7)'}
                borderWidth={'1px'}
                borderStyle={'solid'}
                boxShadow={'0px 0px 10px rgba(220, 11, 155, 0.5)'}
              >
                <Flex mb={'6'} gap={'3'} alignItems={'center'}>
                  <Box>
                    <Image borderRadius={'50%'} src={profile} height={75} width={75} />
                  </Box>
                  <Box>
                    <CustomHeading fontSize={'25px'} mb={'0'} color={'#fff'}>
                      <b>Kierra Bozeman</b>
                    </CustomHeading>
                    <CustomPara fontSize={'14px'} marginBottom={0} >@themajorkie</CustomPara>
                    <CustomPara fontSize={'14px'} marginBottom={0} ><em>Guest</em></CustomPara>
                  </Box>
                </Flex>
                <Flex gap={'3'} >
                  {/* <Box w={'20%'}>
                    <Image src={Qta} />
                  </Box> */}
                  <Box>
                    <CustomPara fontSize={'15px'}>
                      <b>If the bar doesn't have Night District, I'm not going. Using the app makes
                        the whole night out experience smoother and more enjoyable.
                        No more wasting time in lines or struggling to get the bartender's attention.</b>
                    </CustomPara>
                  </Box>
                </Flex>
                <Flex display={'flex'} marginLeft={'0'} >
                  <Icon color={'#ffcc00'} fontSize={'16px'} as={AiFillStar} />
                  <Icon color={'#ffcc00'} fontSize={'16px'} as={AiFillStar} />
                  <Icon color={'#ffcc00'} fontSize={'16px'} as={AiFillStar} />
                  <Icon color={'#ffcc00'} fontSize={'16px'} as={AiFillStar} />
                  <Icon color={'#ffcc00'} fontSize={'16px'} as={AiFillStar} />
                </Flex>
              </Box>
              <Box
                height={375}
                backdropFilter={'blur(12px)'}
                borderRadius={'12'}
                py={'10'}
                px={'12'}
                mb={'6'}
                borderColor={'rgba(220, 11, 155, 0.7)'}
                borderWidth={'1px'}
                borderStyle={'solid'}
                boxShadow={'0px 0px 10px rgba(220, 11, 155, 0.5)'}
              >
                <Flex mb={'6'} gap={'3'} alignItems={'center'}>
                  <Box>
                    <Image borderRadius={'50%'} src={profile2} height={75} width={75} />
                  </Box>
                  <Box>
                    <CustomHeading fontSize={'25px'} mb={'0'} color={'#fff'} >
                      <b>Donald Evans</b>
                    </CustomHeading>
                    <CustomPara fontSize={'14px'} marginBottom={0} >@donny.evans3</CustomPara>
                    <CustomPara fontSize={'14px'} marginBottom={0} ><em>Guest</em></CustomPara>
                  </Box>
                </Flex>
                <Flex gap={'3'} >
                  <Box>
                    <CustomPara fontSize={'15px'}>
                      <b>Waiting in long lines at bars with a bunch of people bumping into me always made me
                        pretty angry, but Night District makes it easy. With their mobile drink ordering, I
                        could skip nonsense. No more frustration, just more time to enjoy my time off.</b>
                    </CustomPara>
                  </Box>
                </Flex>
                <Flex display={'flex'} marginLeft={'0'} >
                  <Icon color={'#ffcc00'} fontSize={'16px'} as={AiFillStar} />
                  <Icon color={'#ffcc00'} fontSize={'16px'} as={AiFillStar} />
                  <Icon color={'#ffcc00'} fontSize={'16px'} as={AiFillStar} />
                  <Icon color={'#ffcc00'} fontSize={'16px'} as={AiFillStar} />
                </Flex>
              </Box>
            </Stack>
          </Stack>
        </Container>
      </Stack>
    </>
  );
}

