import React from 'react';
import {
    Stack,
} from '@chakra-ui/react';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import FeatureMockup from './Mockup';
import FeatureCaption from './Caption';

export default function Feature({ captionHeading, captionPara, mockupImage, Img, reverseOrder }) {
    return (
        <Stack
            direction={{ base: 'column', md: reverseOrder ? 'row-reverse' : 'row' }}
            height={{ base: 'auto', }}
            bgImage={Img}
            bgSize={'cover'}
            bgRepeat={'no-repeat'}
            justifyContent={'center'}
            alignItems={'center'}
            mt={'0 !important'}
            spacing={{ base: 5, md: 8 }}
            pl={{ base: 8, md: 8, lg: 60 }}
            pr={{ base: 8, md: 8, lg: 20, xl: 80 }}
            pt={{ base: 10, lg: 20 }}
            pb={{ base: 10, lg: 20 }}
        >
            <FeatureMockup mockupImage={mockupImage} />
            <FeatureCaption captionHeading={captionHeading} captionPara={captionPara} captionAlign={reverseOrder} />


        </Stack>
    );
}
