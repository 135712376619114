import { Container, Image, Stack, Link, ListItem, useColorModeValue, UnorderedList } from '@chakra-ui/react';
import logo from '../../../assets/images/logo/logo3.png';
import { Link as ReactLink } from 'react-router-dom';
import { NAV_ITEMS } from '../../../utilities/navigationLinks.js'
import AppStore from '../../../assets/images/shorts/app-store.png';
import PlayStore from '../../../assets/images/shorts/playstore.png';
import CustomPara from '../Paragraph/CustomPara';
import { SocialLinks } from '../External/SocialLinks.js';

export default function Index() {
	const linkColor = useColorModeValue('white', 'gray.200');
	//const linkHoverColor = useColorModeValue('gray.800', 'white');
	return (
		<>
			<Stack id='Footer' bg={'#000'} pt={'20'} pb={'7'}>
				<Container maxW={'6xl'} p={{ base: '15px !important', '2xl': 0 }}>
					<Stack textAlign={'center'}>
						<Image src={logo} w={'120px'} m={'auto'} mb={'10'} />
						<UnorderedList mb={'10 !important'} listStyleType={'none'} gap={'6'} display={'flex'} justifyContent={'center'}>
							{NAV_ITEMS?.map((navItem) => (
								<ListItem>
									<Link as={ReactLink} to={navItem.href ?? '#'} color={linkColor}
										fontFamily={'Avenir'} fontSize={{ base: '13px', xl: '17px' }} fontWeight={500}
										p={{ base: 1 }} py={{ base: 5 }} position={'relative'} transition={'0.3s ease-in-out'}
										_hover={{ color: 'primaryHeading.100', _before: { transform: 'scaleX(1)', transformOrigin: 'bottom left' } }}
										_before={{
											content: `""`, w: '100%', h: '3px', bottom: '-2px', left: '0px', borderRadius: '25px', backgroundColor: "primaryHeading.100",
											position: 'absolute', transform: 'scaleX(0)', transformOrigin: 'bottom right', transition: 'transform 0.3s ease-in-out'
										}}
									>
										{navItem.label}
									</Link>
								</ListItem>
							))}
						</UnorderedList>
						<SocialLinks />
						<Stack direction={'row'} justifyContent={'center'} marginBottom={'24 !important'}>
							{/* <Link><Image src={PlayStore} /></Link> */}
							<Link href="https://apps.apple.com/us/app/night-district/id6449729790"><Image src={AppStore} style={{ maxWidth: '250px', maxHeight: '150px' }} /></Link>
						</Stack>
						<CustomPara textAlign={'center'}>Copyright © 2022. All Rights Reserved</CustomPara>
					</Stack>
				</Container>
			</Stack>
		</>
	);
}
