import React from 'react';
import { useEffect } from 'react';
import InerBannner from '../../components/Website/Banners/InerBannner';
import Testimonail from '../../components/Website/Testimonials/Testimonials';
import CustomPara from '../../components/Website/Paragraph/CustomPara';
import PrivacyPolicyComponent from '../../components/Website/Agreements/PrivacyPolicy';

export default function PrivacyPage() {

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  }, [])

  return (
    <>
      <InerBannner simpleHeading={'Privacy Policy'} bannerMessage={''} />
      <PrivacyPolicyComponent />
      <Testimonail />
    </>
  );
}