import React from 'react';
import {
  Box,
  Container,
  Grid,
  GridItem,
  Heading,
  Stack,
  Text,
  Link,
} from '@chakra-ui/react';
import { Link as ReactLink } from 'react-router-dom';
import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import Homebanner from '../../../assets/images/Banner/homebanner1.png';
import Mockup1 from '../../../assets/images/features/1.png';
import Mockup2 from '../../../assets/images/features/2.png';
import Mockup3 from '../../../assets/images/features/3.png';
import Mockup4 from '../../../assets/images/features/4.png';
import Mockup5 from '../../../assets/images/features/5.png';
import AppStore from '../../../assets/images/shorts/app-store.png';

export default function HomeBanner({ MainHeading, Mainpara }) {
  return (
    <Stack
      direction={{ base: 'column', md: 'row' }}
      height={{ base: 'auto', md: '100vh' }}
      bgImage={Homebanner}
      bgSize={'cover'}
      bgRepeat={'no-repeat'}
      justifyContent={'center'}
      alignItems={'center'}
      spacing={{ base: 1, md: 8 }}
      pl={{ base: 8, md: 24, sm: 28 }}
      pr={{ base: 8, md: 24, sm: 28 }}
    >
      <Box
        flex={1}
        pt={{ base: 40, }}
        pl={{ base: 60, md: 20, sm: 60 }}
        pr={{ base: 60, md: 8, sm: 60 }}
        pb={{ base: 10, md: 8, sm: 10 }}
        width="220%"
      >
        <Heading
          lineHeight={1.4}
          fontStyle={'bold'}
          fontSize={{
            base: '36px',
            md: '50px',
            xl: '45px',
            '2xl': '54px',
          }}
          pb={{ base: '4', lg: '8' }}
          color={'white'}
          fontFamily={'Avenir'}
          maxW="800px"
          textAlign={{ base: 'center', md: 'left' }}
          textShadow="0px 0px 8px #fff"
        >
          {MainHeading}
        </Heading>

        <Text
          fontSize={{ base: '14px', md: '14px', lg: '18px' }}
          fontWeight={'bold'}
          color={'white'}
          lineHeight={{ md: '30px', base: '23px' }}
          fontFamily={'Avenir'}
          textAlign={{ base: 'center', md: 'left' }}
        >
          {Mainpara}
        </Text>

        <Link href="https://apps.apple.com/us/app/night-district/id6449729790" pt={5} display="flex" justifyContent={{ base: 'center', md: 'flex-start' }}>
          <img src={AppStore} alt="App Store" style={{ maxWidth: '250px', maxHeight: '150px' }} />
        </Link>
      </Box>

      <Box
        flex={1}
        display={'flex'}
        justifyContent={'center'}
        pt={{ base: 1, md: 120, sm: 1 }}
        pb={{ base: 10 }}
      >
        <Carousel
          showThumbs={false}
          showStatus={false}
          autoPlay
          infiniteLoop
          interval={5000} // Sets time of each slide to 5 seconds
          width="500px"
          height="500px"
        >
          <div>
            <img src={Mockup1} alt="Mockup 1" />
          </div>
          <div>
            <img src={Mockup2} alt="Mockup 2" />
          </div>
          <div>
            <img src={Mockup3} alt="Mockup 3" />
          </div>
          <div>
            <img src={Mockup4} alt="Mockup 4" />
          </div>
          <div>
            <img src={Mockup5} alt="Mockup 5" />
          </div>
        </Carousel>
      </Box>
    </Stack>
  );
}
