import React from 'react';
import { Link, ListItem, UnorderedList } from '@chakra-ui/react';
import { FaFacebookF, FaLinkedinIn, FaInstagram, FaTwitter } from 'react-icons/fa';
import { Icon } from '@chakra-ui/icons';

// Night District's Social Presence
export const SocialLinks = () => {
	return (
		<>
			<UnorderedList mb={'10 !important'} listStyleType={'none'} gap={'6'} display={'flex'} justifyContent={'center'}>
				{/* <!-- FACEBOOK --> */}
				<ListItem>
					<Link href="https://www.facebook.com/NightDistrictLLC" isExternal>
						<Icon color={'white'} fontSize={'23px'} _hover={{ color: '#bc0c89' }} as={FaFacebookF} />
					</Link>
				</ListItem>
				{/* <!-- TWITTER --> */}
				<ListItem>
					<Link href="https://twitter.com/night_district_" isExternal>
						<Icon color={'white'} fontSize={'23px'} _hover={{ color: '#bc0c89' }} as={FaTwitter} />
					</Link>
				</ListItem>
				{/* <!-- INSTAGRAM --> */}
				<ListItem>
					<Link href="https://www.instagram.com/nightdistrictapp" isExternal>
						<Icon color={'white'} fontSize={'23px'} _hover={{ color: '#bc0c89' }} as={FaInstagram} />
					</Link>
				</ListItem>
				{/* <!-- LINKEDIN --> */}
				<ListItem>
					<Link href="https://www.linkedin.com/company/101816126/admin/feed/posts/" isExternal>
						<Icon color={'white'} fontSize={'23px'} _hover={{ color: '#bc0c89' }} as={FaLinkedinIn} />
					</Link>
				</ListItem>
			</UnorderedList>
		</>
	);
}
