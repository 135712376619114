import React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Button, Checkbox, Container, Img, Input, Link, Stack, Text, useToast } from '@chakra-ui/react';
import { HeadFootEnabler } from '../../../utilities/HeadFootEnabler.js';
import Signupimg from '../../../assets/images/Banner/signup.jpg';
import CustomHeading from '../../../components/Website/Headings/CustomHeading.js';
import logo from '../../../assets/images/Banner/signlogo.png';
import { useEffect } from 'react';
import CustomPara from '../../../components/Website/Paragraph/CustomPara.js';
import { Link as ReactLink } from 'react-router-dom';
import { useState } from 'react';
import { POST } from '../../../utilities/ApiProvider.js';
import { SocialSignIn } from '../../../components/Website/External/SocialSignIn.js';
//import axios from 'axios';
//import { baseUrl } from '../../../utilities/Config.js';

export default function Index() {
	const location = useLocation();
	const navigate = useNavigate();

	useEffect(() => {
		HeadFootEnabler(location);
	}, [location]);

	// console.log(baseUrl);

	const signupstyle = {
		outline: '1px solid #fff',
		py: '25px',
		bg: '#271623b5',
		color: '#fff'
	};

	const toast = useToast();
	const [isLoading, setisLoading] = useState(false);
	const [Fields, setFields] = useState({
		username: '',
		email: '',
		password: '',
		confirm_password: '',
		role: 'bouncer', //this will probably become contextual at some point.
	});

	const [agreement, setagreement] = useState(false);

	const submitForm = async()=> {
		try {
			setisLoading(true);
			const formData = new FormData();

			if (
				Fields.username === '' ||
				Fields.email === '' ||
				Fields.password === '' ||
				Fields.confirm_password === ''
			) {
				toast({
					status: 'error',
					title: 'Please fill in all the fields to proceed further.',
					duration: 7000,
					isClosable: true,
					position: 'bottom-left',
				});
				setisLoading(false);
				return;
			}

			if (!agreement) {
				toast({
					status: 'error',
					title: 'Please agree to term and condition for proceed further.',
					duration: 7000,
					isClosable: true,
					position: 'bottom-left',
				});
				setisLoading(false);
				return;
			}

			Fields.role = 'bouncer';

			let data = Fields;

			if (Fields.username !== Fields.username.toLowerCase()) {
				toast({
					description: 'User name should be in lower case',
					status: "error",
					isClosable: true,
					position: 'bottom-left',
					duration: 2500,
				});
				setisLoading(false);
				return;
			}
			if (Fields.password !== Fields.confirm_password) {
				toast({
					description: "Password and Confirm Password doesn't match",
					status: "error",
					isClosable: true,
					position: 'bottom-left',
					duration: 2500,
				});
				setisLoading(false);
				return;
			}

			let response = await POST(`users`, Fields);

			if (response.status !== 200) {
				toast({
					description: response.message,
					status: response.status > 299 ? 'error' : response.status < 300 ? 'success' : 'info' ,
					isClosable: true,
					position: 'bottom-left',
					duration: 2500,
				});
				setisLoading(false);
				return;
			}
			navigate('/dashboard/login');

			toast({
				description: response.message,
				status: response.status > 299 ? 'error' : response.status < 300 ? 'success' : response.status ,
				isClosable: true,
				position: 'bottom-left',
				duration: 2500,
			});


			// setFields({
			//	 username: '',
			//	 email: '',
			//	 password: '',
			//	 confirm_password: ''
			// });

			// setagreement(false);
			setisLoading(false);
		} catch (err) {
			toast({
				description: 'Something went wrong!',
				status: 'error',
				isClosable: true,
				position: 'bottom-left',
				duration: 2500,
			});
		}
	};

	const lnk = { color: 'pHeading.100' };

	return (
		<>
			<Stack py={'48'}
				backgroundRepeat={'no-repeat'}
				backgroundImage={Signupimg}
			>
				<Container maxW={'6xl'}>
					<Stack mb={'12'}>
						<Img margin={'auto'} mb={'4'} w={'150px'} src={logo} />
						<CustomHeading fontSize={{ base: "25px", lg: "40px" }} color={'#fff'}>
							Welcome to Night District
						</CustomHeading>
						<CustomPara textAlign={'center'}>Enter your details</CustomPara>
					</Stack>

					<Stack mb={'8'} px={{ base: "none", lg: "12px" }}
						flexWrap={'wrap'} spacing={'0'} gap={'6'}
						direction={'row'} justifyContent={'space-between'}
					>
						{/* <!-- FIRST NAME ---> */}

						{/* <!-- LAST NAME ---> */}

						{/* <!-- USERNAME ---> */}
						<Input sx={signupstyle}
							placeholder={'Name'} type="Name"
							_placeholder={{ color: '#fff' }} value={Fields.username}
							onChange={(e)=> { setFields({ ...Fields, username: e.target.value }); }}
						/>

						{/* <!-- EMAIL ADDRESS ---> */}
						<Input sx={signupstyle}
							placeholder={'Email Address'} type="email"
							_placeholder={{ color: '#fff' }} value={Fields.email}
							onChange={(e)=> { setFields({ ...Fields, email: e.target.value }); }}
						/>

						{/* <!-- PASSWORD ---> */}
						<Input sx={signupstyle} 
							placeholder={'Password'} type="Password"
							_placeholder={{ color: '#fff' }} value={Fields.password}
							onChange={(e)=> { setFields({ ...Fields, password: e.target.value }); }}
						/>

						{/* <!-- CONFIRM PASSWORD ---> */}
						<Input sx={signupstyle}
							placeholder={'Confirm Password'} type="Password"
							_placeholder={{ color: '#fff' }} value={Fields.confirm_password}
							onChange={(e)=> { setFields({ ...Fields, confirm_password: e.target.value }); }}
						/>

						{/* <!-- TERMS OF SERVICE & PRIVACY POLICY CHECKBOX --> */}
						{/* <!--
							TODO:
								if they click these links during signup,
								display the content in a modal rather than redirect the page.
						--> */}
						<Checkbox color={'#fff'} colorScheme="pink"
							isChecked={agreement} onChange={(e)=> { setagreement(e.target.checked); }}
						>{' '}I agree to the{' '}
							<Link sx={lnk} as={ReactLink} to={'/TermsPage'}>Terms of Service</Link>
							{' '}&{' '}
							<Link sx={lnk} as={ReactLink} to={'/PrivacyPage'}>Privacy Policy</Link>
							{' '}of Night District.
						</Checkbox>
					</Stack>

					<Stack mb={'12'}>
						{/* <!-- SUBMIT BUTTON ---> */}
						<Button onClick={() => submitForm()}
							bgColor={'#dc0b9b'} color={'#fff'}
							fontWeight={'600'} fontSize={'17px'}
							w={"100%"} py={6} px={'12'} margin={'auto'}
							borderRadius={6} border={'2px solid #fff'} borderColor={'#bc0c89'}
							_hover={{ bgColor: 'transparent', color: '#fff' }}
							isLoading={isLoading}
						>Submit</Button>
					</Stack>

					<Stack textAlign={'center'} mb={'4'}>
						{/* <!-- ALREADY A MEMBER? --> */}
						<Text color={'#fff'}>Already A Member?{'  '}
							<Link color={'#bc0c89'} as={ReactLink} to={'/dashboard/login'}>Login</Link>
						</Text>
					</Stack>

					{/* <!-- SOCIAL SIGN-IN --> */}
					<SocialSignIn />

				</Container>
			</Stack>
		</>
	);
}
