import {
  Box,
  Button,
  Flex,
  Img,
  Stack,
  Modal,
  ModalContent,
  ModalHeader,
  Text,
  ModalBody,
  ModalCloseButton,
  ModalFooter,
  ModalOverlay,
  useDisclosure,
  Input,
  Textarea,
  FormControl,
  Checkbox,
  useToast,
  FormLabel,
  Select,
  Switch,
} from '@chakra-ui/react';
import React, { useEffect } from 'react';
import CustomHeading from '../../../components/Website/Headings/CustomHeading';
import MainDashboard from '../MainDashboard';
import { AiOutlineSearch, AiOutlinePlusCircle } from 'react-icons/ai';
import { Icon } from '@chakra-ui/icons';
import BorderButton from '../../../components/Website/Buttons/BorderButton';
import cat1 from '../../../assets/images/menu/c1.jpg';
import menu1 from '../../../assets/images/menu/menu1.jpg';
import CustomPara from '../../../components/Website/Paragraph/CustomPara';
import { useState } from 'react';
import { Link as ReactLink, useNavigate } from 'react-router-dom';
import { GET, POST } from '../../../utilities/ApiProvider';
import { baseUrl, imgUrl } from '../../../utilities/Config';
import { useSelector } from 'react-redux';

export default function Menu() {
  const [posts, setPost] = useState([]);
  const [Hashtags, setHashtags] = useState([]);
  const [hashtagData, sethashtagData] = useState([]);
  const [SuperCategories, setSuperCategories] = useState([]);
  const [subcategories, setChildCategories] = useState([]);
  const toast = useToast();
  const [isLoading, setisLoading] = useState(false);

  const OverlayOne = () => (
    <ModalOverlay
      bg="blackAlpha.300"
      backdropFilter="blur(10px) hue-rotate(90deg)"
    />
  );

  const { isOpen, onOpen, onClose } = useDisclosure();
  const [overlay, setOverlay] = React.useState(<OverlayOne />);

  useEffect(() => {
    getHastags();
    getPosts();
    getSuperCategories();
    getMenuItems();
  }, []);

  const getPosts = async () => {
    var response = await GET(`post`);
    setPost(response.data);
  };
  const getSuperCategories = async () => {
    var response = await GET(`admin/category`);
    setSuperCategories(response.data);
    console.log("res", response);
  };

  function itemExists(value) {
    return SuperCategories.some(function (el) {
      if (el._id === value) {
        setChildCategories(el.subcategories);
      }
    });
  }

  const getHastags = async () => {
    var response = await GET(`admin/hashtag`);
    setHashtags(response.data);
  };

  const [Fields, setFields] = useState({
    title: '',
    description: '',
    type: 'bar',
    superItem: "",
    category: '',
    subcategory: '',
    variation: [],
  });
  let hastagArray = [];

  const addItem = async () => {
    try {
      const formData = new FormData();

      formData.append('title', Fields.heading);
      formData.append('description', Fields.description);
      formData.append('hastags', hashtagData);

      var response = await POST('/post', formData);

      toast({
        description: response.message,
        status: response.status,
        isClosable: true,
        position: 'bottom-left',
        duration: 2500,
      });

      setFields({
        username: '',
        password: '',
      });

      setisLoading(false);
    } catch (err) {
      toast({
        description: 'Something went wrong!',
        status: 'error',
        isClosable: true,
        position: 'bottom-left',
        duration: 2500,
      });
    }
  };

  const user = useSelector(state => state?.value);

  useEffect(() => {
    let creds = localStorage.getItem('userCreds');
    console.log(creds)
    if (creds) {
      setFields(JSON.parse(creds));
    } else {
      setFields({
        username: '',
        password: '',
        role: "barowner",
        fcm: ""
      });
    }

  }, [user]);

  useEffect(() => {
    if (user === null) {
      navigate("/dashboard/login");
    }
  }, [user]);

  const submitForm = async () => {
    const formData = new FormData();
    if (
      !Fields.title === '' ||
      !Fields.description === '' ||
      !Fields.superItem === ''
    ) {
      toast({
        position: 'bottom-left',
        isClosable: true,
        description: 'PLease fill  all the fields',
        status: 'error',
        duration: 5000,
      });
      return;
    }

    let variations = listOfSuperItems?.servings?.map(val => {
      return {
        variant: val?._id,
        price: val?.price ?? ""
      }
    })

    let data = {
      menu: [{
        title: Fields?.title,
        description: Fields?.description,
        superItem: Fields?.superItem,
        variation: variations
      }]
    }

    try {
      const res = await POST("bar/item", data, {
        authorization: `bearer ${user?.verificationToken}`
      });
      if (res.status == 200) {
        toast({
          status: 'success',
          title: 'Item added!',
          isClosable: true,
          position: 'bottom-left',
          duration: 5000,
        });
        getMenuItems();
        getAllCategoriesWithItems();
        onClose();
      } else {
        toast({
          description: res?.data?.message,
          status: "error",
          isClosable: true,
          position: 'bottom-left',
          duration: 5000,
        });
        return;
      }

      formData.append('title', Fields.heading);
      formData.append('description', Fields.description);
      formData.append('hastags', hashtagData);

      // var response = await POST('/post', Fields);

      // toast({
      //   description: response.message,
      //   status: response.status,
      //   isClosable: true,
      //   position: 'bottom-left',
      //   duration: 2500,
      // });

      setFields({
        username: '',
        password: '',
      });

      setisLoading(false);
    } catch (err) {
      toast({
        description: 'Something went wrong!',
        status: 'error',
        isClosable: true,
        position: 'bottom-left',
        duration: 2500,
      });
    }
  };

  const signupstyle = {
    outline: '1px solid #fff',
    py: '25px',
    bg: '#271623b5',
    color: '#fff',
  };
  const [localItem, setLocalItem] = useState(false);

  const navigate = useNavigate();

  useEffect(() => {
    const localFun = async () => {
      if (localStorage.getItem) {
        await setLocalItem(true);
      }
    };
    localFun();
  }, []);

  const [menuItems, setMenuItems] = useState([]);

  const getMenuItems = async () => {
    let response = await GET(`bar/item/${user?.barInfo}`, {
      authorization: `Bearer ${user?.verificationToken}`,
    });
    console.log("res", response?.data);
    if (response?.message === 'success') {
      console.log(response?.data);
      setMenuItems(response?.data);
    }
  };
  const [data, setData] = useState([]);

  const getPourType = async () => {
    const res = await GET('admin/pourtype', {
      authorization: `bearer ${user?.verificationToken}`,
    });
    setData(res?.data);
  };



  // NEW CHANGES

  const [categoriesWithInfo, setCategoriesWithInfo] = useState([]);
  const [itemsOfSelectedCategory, setItemsOfSelectedCategory] = useState({
    id: "",
    items: []
  });
  const [listOfSuperItems, setListOfSuperItems] = useState([]);

  const getAllCategoriesWithItems = async () => {
    const res = await GET(`admin/parentcategory2?barId=${user?.barInfo}`, {
      authorization: `bearer ${user?.verificationToken}`,
    });
    if (res.status === 200) setCategoriesWithInfo(res?.data);
  };

  const getSuperItemsFromCat = async () => {
    const res = await GET(`admin/category2/${itemsOfSelectedCategory?.id}`)
    console.log(res)
    setListOfSuperItems(res?.data)
  }

  useEffect(() => {
    if (user) {
      getAllCategoriesWithItems();
      getPourType();
    }
  }, [user]);

  useEffect(() => {
    if (itemsOfSelectedCategory?.id) getSuperItemsFromCat()
  }, [itemsOfSelectedCategory])

  function updateServingPrice(data, servingId, newPrice) {
    const newData = data; // Create a shallow copy of the original data
    console.log(newData);

    // Find the serving item by _id and update its price
    newData.forEach((serving) => {
      if (serving._id === servingId) {
        serving.price = newPrice;
      }
    });

    setListOfSuperItems({
      ...listOfSuperItems,
      servings: [...newData]
    })
    // return newData; // Return the modified data
  }

  return (
    <>

      <MainDashboard>
        <Modal size={'3xl'} isCentered isOpen={isOpen} onClose={onClose}>
          {overlay}
          <ModalContent bg={'dashbg.100'}>
            <ModalHeader>
              <CustomPara marginBottom={'0'} fontSize={'20px'}>
                Enter Menu Details
              </CustomPara>
            </ModalHeader>
            <ModalCloseButton color={'#fff'} />
            <ModalBody>
              <Stack gap={'4'}>
                <Input
                  sx={signupstyle}
                  placeholder={'Title'}
                  type="Name"
                  _placeholder={{ color: '#fff' }}
                  value={Fields.title}
                  onChange={e => {
                    setFields({
                      ...Fields,
                      title: e.target.value,
                    });
                  }}
                />
                <Textarea
                  sx={signupstyle}
                  placeholder={'Description'}
                  type="Name"
                  _placeholder={{ color: '#fff' }}
                  value={Fields.description}
                  onChange={e => {
                    setFields({
                      ...Fields,
                      description: e.target.value,
                    });
                  }}
                ></Textarea>

                <Box w={'full'} position={'relative'}>
                  <Select
                    size="lg"
                    color={'#fff'}
                    outline={'1px solid #fff'}
                    placeholder="Select option"
                    onChange={e => {
                      setFields({ ...Fields, superItem: e.target.value });
                      itemExists(e.target.value);
                    }}
                  >
                    {listOfSuperItems?.list?.length > 0 && listOfSuperItems?.list?.map((e, i) => {
                      console.log(e);
                      return (
                        <option key={i} value={e._id}>
                          {e.menu_name ?? 'default'}
                        </option>
                      );
                    })}
                  </Select>
                </Box>
                {/* <Box w={'full'} position={'relative'}>
                  <Select
                    size="lg"
                    color={'#fff'}
                    outline={'1px solid #fff'}
                    placeholder="Select option"
                  >
                    {subcategories.map((e, i) => {
                      return (
                        <option key={i} value={e._id}>
                          {e.name}
                        </option>
                      );
                    })}
                  </Select>
                </Box> */}
                <Stack>
                  <CustomPara>$Price</CustomPara>
                  <Stack direction={'row'} justifyContent={'space-between'}>
                    {listOfSuperItems?.servings?.length > 0 ? (
                      listOfSuperItems?.servings?.map(item => {
                        return (
                          <Box key={item?._id}>
                            <FormControl>
                              <FormLabel color={'#fff'} fontSize={'14px'}>
                                {item?.name}
                              </FormLabel>
                              <Input
                                sx={signupstyle}
                                type="price"
                                placeholder="$Price"
                                onChange={e => {
                                  updateServingPrice(listOfSuperItems?.servings, item?._id, e.target.value);

                                  const newVariation = {
                                    variant: item?._id,
                                    price: e?.target?.value,
                                  };

                                  const variationIndex =
                                    Fields.variation.findIndex(
                                      v => v.variant === item._id
                                    );

                                  if (variationIndex !== -1) {
                                    // If variant already exists, update the price
                                    const updatedVariation = {
                                      ...Fields.variation[variationIndex],
                                      price: e.target.value,
                                    };

                                    const updatedVariations = [
                                      ...Fields.variation,
                                    ];
                                    updatedVariations[variationIndex] =
                                      updatedVariation;

                                    setFields({
                                      ...Fields,
                                      variation: updatedVariations,
                                    });
                                  } else {
                                    // If variant doesn't exist, add it to the array
                                    setFields({
                                      ...Fields,
                                      variation: [
                                        ...Fields.variation,
                                        newVariation,
                                      ],
                                    });
                                  }
                                }}
                              />
                            </FormControl>
                          </Box>
                        );
                      })
                    ) : (
                      <Text>No Data Found</Text>
                    )}
                  </Stack >
                </Stack >
              </Stack >
            </ModalBody >
            <ModalFooter>
              <Stack direction={'row'} w={'full'} justifyContent={'center'}>
                <Button
                  onClick={e => submitForm()}
                  bg={'pHeading.100'}
                  color={'#fff'}
                  px={'14'}
                >
                  Add Menu
                </Button>
                <Button onClick={onClose}>Discard</Button>
              </Stack>
            </ModalFooter>
          </ModalContent >
        </Modal >
        <Stack p={'4'} gap={'8'}>
          <Stack direction={{ base: "column", lg: "row" }} justifyContent={'space-between'}>
            <Box display={'flex'} flexDirection={{ base: "column", lg: "row" }} alignItems={'center'} gap={'4'}>
              <CustomHeading
                fontSize={'30px'}
                color={'#fff'}
                textAlign={'left'}
              >
                Category
              </CustomHeading>
              <FormControl position={'relative'}>
                <Input
                  color={'#fff'}
                  border={'1px solid #fff !important'}
                  _placeholder={{ color: '#fff' }}
                  placeholder="Search"
                />
                <Button bg={'transparent'} right={'0'} position={'absolute'}>
                  <Icon
                    color={'white'}
                    fontSize={'20px'}
                    as={AiOutlineSearch}
                  />
                </Button>
              </FormControl>
            </Box>
            <Box>
              <Button
                isDisabled={itemsOfSelectedCategory?.id ? false : true}
                bg={'transparent'}
                textAlign={'center'}
                margin={'auto'}
                py={'10px'}
                px={'8'}
                lineHeight={'inherit'}
                border={'1px solid #fff'}
                borderRadius={'6px'}
                width={{ base: "100%", lg: "90%" }}
                color={'#fff'}
                _hover={{
                  color: 'primaryText.200',
                }}
                onClick={() => {
                  setOverlay(<OverlayOne />);
                  onOpen();
                }}
              >
                Add Drinks
              </Button>
            </Box>
          </Stack>
          <Stack
            direction={{ base: 'column', lg: 'row' }}
            alignItems={'center'}
            gap={'4'}
          >
            {
              categoriesWithInfo?.length > 0 &&
              categoriesWithInfo?.map(val => {
                return (
                  <Box
                    onClick={() => {
                      console.log(val?.items)
                      setItemsOfSelectedCategory({
                        id: val?._id,
                        items: val?.items
                      })
                    }}
                    key={val?._id}
                    cursor={'pointer'}
                    position={'relative'}
                    pr={'4'}
                    border={'2px solid'}
                    borderColor={itemsOfSelectedCategory?.id === val?._id ? "#d53f8c" : "#1a1a1a"}
                    display={'flex'}
                    justifyContent={'right'}
                    alignItems={'flex-end'}
                    borderRadius={'8px'}
                    h={'125px'}
                    w={'258px'}
                    zIndex={'1'}
                    backgroundImage={`${imgUrl}${val?.category_image}`}
                    _before={{
                      content: "''",
                      w: '100%',
                      h: '100%',
                      position: 'absolute',
                      bg: '#000',
                      right: '0',
                      left: '0',
                      zIndex: '-1',
                      borderRadius: '6px',
                      opacity: '0.6',
                    }}
                  >
                    <CustomHeading
                      fontSize={'18px'}
                      fontWeight={'600'}
                      color={'#fff'}
                    >
                      {val?.name}
                    </CustomHeading>
                  </Box>
                )
              })
            }
          </Stack>
          <Stack>
            <Box>
              <CustomHeading
                fontSize={'30px'}
                color={'#fff'}
                textAlign={{ base: "center", lg: "left" }}
                mb="20px"
              >
                All Menu
              </CustomHeading>
            </Box>
            <Stack
              direction={{ base: 'column', lg: 'row' }}
              flexWrap={'wrap'}
              alignItems={'center'}
              gap={'4'}
            >
              {itemsOfSelectedCategory?.items?.length > 0 &&
                itemsOfSelectedCategory?.items?.map(item => {
                  console.log(item)
                  return (
                    <Box
                      key={item?._id}
                      w={'300px'}
                      as={ReactLink}
                      to={'/dashboard/singlemenu'}
                      state={item}
                    >
                      <Img
                        w={'200px'}
                        objectFit={'contain'}
                        height={'300px'}
                        marginX={'auto'}
                        src={`${imgUrl}/${item?.pictures[0]}` ?? menu1}
                      />
                      <Stack p={'3'} bg={'dashbg.100'}>
                        <CustomHeading
                          textAlign={'left'}
                          color={'#fff'}
                          mb={'0'}
                          fontSize={'21px'}
                        >
                          {item?.menu_name}
                        </CustomHeading>
                        <CustomPara color={'brand.800'} fontSize={'14px'} height={'75px'} overflow="hidden" overflowY={'auto'}>
                          {item?.description}
                        </CustomPara>
                        <Box>
                          <Flex gap={'2'}>
                            <CustomHeading
                              mb={'0'}
                              color={'#fff'}
                              fontSize={'17px'}
                              textAlign={'left'}
                            >
                              Category:{' '}
                            </CustomHeading>
                            <CustomPara>
                              {' '}
                              {item?.category['name'] ?? 'Spirits'}
                            </CustomPara>
                          </Flex >
                          <Flex gap={'2'}>
                            <CustomHeading
                              color={'#fff'}
                              fontSize={'17px'}
                              textAlign={'left'}
                            >
                              Subcategory:{' '}
                            </CustomHeading>
                            <CustomPara>
                              {item?.subCategory['name'] ?? 'Spirits'}
                            </CustomPara>
                          </Flex>
                          <Stack
                            border={'1px solid #fff'}
                            borderRadius={6}
                            p={2}
                            textAlign={'center'}
                            color={'#fff'}
                          >
                            <Text>${item?.variation[0]['price'] ?? '0'}</Text>
                          </Stack>
                        </Box>
                      </Stack >
                    </Box >
                  )
                })
              }
            </Stack >
          </Stack >
        </Stack >
      </MainDashboard >
    </>
  );
}
