import { Stack, Text } from '@chakra-ui/react';
import HomeBanner from '../../components/Website/Banners/HomeBanner';
import Waitlist from '../../components/Website/Waitlist/Waitlist';
import About from '../../components/Website/About';
import AppCta from '../../components/Website/Appcta/AppCta';
import Subscribe from '../../components/Website/Subscribe/Subscribe';
import Contact from '../../components/Website/Contact/Contact';
import Featured from '../../components/Website/Featured/Featured';
import Testimonials from '../../components/Website/Testimonials/Testimonials';
import Feature from '../../components/Website/Feature/Feature';
import { useEffect } from 'react';
import Background1 from '../../assets/images/Banner/background11.png';
import Background2 from '../../assets/images/Banner/background14.png';
import Background3 from '../../assets/images/Banner/background17.png';
import Mockup1 from '../../assets/images/features/1.png';
import Mockup2 from '../../assets/images/features/2.png';
import Mockup3 from '../../assets/images/features/3.png';

export default function Home() {

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  }, [])

  return (
    <Stack width="100vw" maxWidth="100%" overflowX="hidden">
      <HomeBanner MainHeading={"Revolutionize Your Nightlife with Night District"} Mainpara={"Night District is a platform where users can effortlessly discover and explore drinks at bars and nightclubs."} />
      <Feature
        captionHeading={"Order Your Drink From Your Phone"}
        captionPara={"Order your favorite drink with just a tap! With Night District, you can order drinks directly from your phone. No more waiting in long lines at the bar."}
        mockupImage={Mockup1}
        Img={Background1}
        reverseOrder={false}
      />
      <Feature
        captionHeading={"Pay From Your Phone"}
        captionPara={"Seamlessly pay for your drinks with Night District! No more waiting in line or searching for your wallet. Simply tap, pay, and enjoy your night out."}
        mockupImage={Mockup2}
        Img={Background2}
        reverseOrder={true}
      />
      <Feature
        captionHeading={"Find Events Near You"}
        captionPara={"Discover exciting events right in your city! With Night District, finding local events has never been easier."}
        mockupImage={Mockup3}
        Img={Background3}
        reverseOrder={false}
      />
      <Waitlist />
      {/* <About /> */}
      {/* <AppCta /> */}
      {/* <Subscribe /> */}
      {/* <Featured/> */}
      <Testimonials />
    </Stack>
  );
}
