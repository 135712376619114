import {
  Box,
  Button,
  Container,
  Flex,
  Link,
  Stack,
  Textarea,
  useToast,
  useBreakpointValue,
} from '@chakra-ui/react';
import React from 'react';
import Contactimg from '../../../assets/images/Banner/background4.png';
import CustomHeading from '../Headings/CustomHeading';
import { useState } from 'react';
import CustomPara from '../Paragraph/CustomPara';
import { FiPhoneCall, FiMail } from 'react-icons/fi';
import { Icon } from '@chakra-ui/icons';
import { Link as ReactLink } from 'react-router-dom';
import ContactFields from './ContactFields';
import { POST } from '../../../utilities/ApiProvider';

export default function Contact() {
  const toast = useToast();
  const [isLoading, setisLoading] = useState(false);
  const [Fields, setFields] = useState({
    name: '',
    city: '',
    email: '',
    phone: '',
    message: '',
  });

  const submitForm = async () => {
    try {
      setisLoading(true);
      const formData = new FormData();

      if (
        Fields.name === '' &&
        Fields.city === '' &&
        Fields.email === '' &&
        Fields.phone === '' &&
        Fields.message === ''
      ) {
        toast({
          status: 'error',
          title: 'Please fill in all the fields to proceed further.',
          duration: 7000,
          isClosable: true,
          position: 'bottom-left',
        });
        setisLoading(false);
        return;
      }

      formData.append('action', 'CONTACT');
      formData.append('name', Fields.name);
      formData.append('city', Fields.city);
      formData.append('email', Fields.email);
      formData.append('phone', Fields.phone);
      formData.append('message', Fields.message);

      let response = await POST('/mailtest/emailer.php', formData, {
        'Content-Type': 'application/x-www-form-urlencoded',
      });

      toast({
        description: response.message,
        status: response.status,
        isClosable: true,
        position: 'bottom-left',
        duration: 2500,
      });

      setFields({
        name: '',
        city: '',
        email: '',
        phone: '',
        message: '',
      });

      setisLoading(false);
    } catch (err) {
      toast({
        description: 'Something went wrong!',
        status: 'error',
        isClosable: true,
        position: 'bottom-left',
        duration: 2500,
      });
    }
  };

  // Use useBreakpointValue to adjust layout for smaller screens
  const formWidth = useBreakpointValue({ base: '100%', lg: '70%' });

  return (
    <>
      <Stack
        bgImg={Contactimg}
        py={36}
        bgSize={'cover'}
        bgRepeat={'no-repeat'}
        bgAttachment={{ base: 'fixed', md: 'fixed' }}
        mt={'0 !important'}
      >
        <Container maxW={'6xl'} p={{ base: '15px !important', '2xl': 0 }}>
          <Box mb={'12'}>
            <CustomHeading textAlign={'center'} color={'#fff'}>
              <b>Contact Us</b>
            </CustomHeading>
            <CustomPara textAlign={'center'}>
              We're here to answer any questions you may have about Night
              District.
            </CustomPara>
          </Box>
          <Stack direction={{ base: 'column', lg: 'row' }} gap={'12'}>
            {/* Contact Information Section */}
            <Box w={{ base: '100%', lg: '30%' }}>
              <CustomHeading
                textAlign={'left'}
                color={'#fff'}
                fontSize={'30px'}
                mb={'7'}
              >
                <b>Contact Information</b>
              </CustomHeading>
              <Stack gap={'4'}>
                <Flex alignItems={'center'} gap={'6'}>
                  <Box
                    display={'flex'}
                    alignItems={'center'}
                    justifyContent={'center'}
                    bg={'#dc0b9b'}
                    w={'60px'}
                    h={'60px'}
                    borderRadius={'6px'}
                  >
                    <Icon color={'white'} fontSize={'30px'} as={FiPhoneCall} />
                  </Box>
                  <Box>
                    <CustomHeading
                      fontSize={'20px'}
                      textAlign={'left'}
                      mb={'5px'}
                    >
                      <b>Call Us</b>
                    </CustomHeading>
                    <Link color={'#fff'}>
                      <b>(502)-671-3113</b>
                    </Link>
                  </Box>
                </Flex>
                <Flex alignItems={'center'} gap={'6'}>
                  <Box
                    display={'flex'}
                    alignItems={'center'}
                    justifyContent={'center'}
                    bg={'#dc0b9b'}
                    w={'60px'}
                    h={'60px'}
                    borderRadius={'6px'}
                  >
                    <Icon color={'white'} fontSize={'30px'} as={FiMail} />
                  </Box>
                  <Box>
                    <CustomHeading
                      fontSize={'20px'}
                      textAlign={'left'}
                      mb={'5px'}
                    >
                      <b>Email Us</b>
                    </CustomHeading>
                    <Link color={'#fff'}>
                      <b>support@nightdistrict.co</b>
                    </Link>
                  </Box>
                </Flex>
              </Stack>
            </Box>
            {/* End Contact Information Section */}

            {/* Contact Form Section */}
            <Box
              w={formWidth}
              px={'8'}
              py={20}
              borderRadius={15}
              borderColor={'rgba(220, 11, 155, 0.7)'}
              borderWidth={'1px'}
              borderStyle={'solid'}
              boxShadow={'0px 0px 10px rgba(220, 11, 155, 0.5)'}
              backdropFilter={'blur(15px)'}
              bgGradient="linear(to-r, rgba(0, 0, 0, 0.1), rgba(255, 255, 255, 0))"
            >
              <CustomHeading
                color={'#fff'}
                fontSize={'30px'}
                textAlign={'center'}
                mb={'20px'}
                fontFamily={'Avenir'}
              >
                <b>Get In Touch With Us</b>
              </CustomHeading>
              <Box
                display={'flex'}
                flexWrap={'wrap'}
                gap={5}
                justifyContent={'space-between'}
              >
                {/* Contact Form Fields */}
                <ContactFields
                  type={'text'}
                  placeholder={'Name'}
                  name={'name'}
                  value={Fields.name}
                  setFields={name => setFields({ ...Fields, name })}
                />
                <ContactFields
                  type={'text'}
                  placeholder={'City'}
                  name={'city'}
                  value={Fields.city}
                  setFields={city => setFields({ ...Fields, city })}
                />
                <ContactFields
                  type={'text'}
                  placeholder={'Email Address'}
                  name={'email'}
                  value={Fields.email}
                  setFields={email => setFields({ ...Fields, email })}
                />
                <ContactFields
                  type={'text'}
                  placeholder={'Phone Number'}
                  name={'phone'}
                  value={Fields.phone}
                  setFields={phone => setFields({ ...Fields, phone })}
                />
                <Textarea
                  py={'4'}
                  height={'100px'}
                  placeholder={'Message'}
                  name={'message'}
                  value={Fields.message}
                  onChange={e =>
                    setFields({ ...Fields, message: e.target.value })
                  }
                  fontSize={'14px'}
                  fontFamily={'Avenir'}
                  border={'none'}
                  fontWeight={700}
                  backdropFilter={'blur(45px)'}
                  resize={'none'}
                  borderRadius={'10'}
                  focusBorderColor={'#dc0b9b'} // Pink border color when focused
                  bg={'rgba(33, 33, 33, 0.3)'} // Transparent black background color
                  _placeholder={{ color: '#fff' }}
                ></Textarea>
                <Box
                  display={'flex'}
                  justifyContent={'center'}
                  width={'100%'}
                >
                  <Button
                    onClick={() => submitForm()}
                    bgGradient={'linear(to-r, #dc0b9b, #BD0684)'}
                    color={'#fff'}
                    borderRadius={12}
                    fontWeight={'900'}
                    px={'80px'}
                    py={6}
                    fontSize={'18px'}
                    borderColor={'primaryOrange.100'}
                    _hover={{
                      bgColor: 'white !important',
                      color: 'black',
                      borderColor: '#dc0b9b', // Pink border color
                    }}
                    isLoading={isLoading}
                    boxShadow={'0px 0px 10px rgba(0, 0, 0, 0.3)'} // Added shadow effect
                  >
                    Submit
                  </Button>
                </Box>
              </Box>
            </Box>
            {/* End Contact Form Section */}
          </Stack>
        </Container>
      </Stack>
    </>
  );
}
