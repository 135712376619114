import { Input } from '@chakra-ui/react'

export default function ContactFields({ type, placeholder, value, setFields, name }) {
    return (
        <Input
            w={placeholder !== 'Message' ? { base: '100%', md: '48.5%' } : '100%'}
            py={'6'}
            type={type}
            placeholder={placeholder}
            value={value}
            onChange={(e) => setFields(e.target.value)}
            fontSize={'14px'}
            fontFamily={'Avenir'}
            border={'none'} // Pink border color
            borderRadius={'10'}
            fontWeight={700}
            color={'#fff !important'}
            backdropFilter={'blur(45px)'}
            name={name}
            focusBorderColor={'#dc0b9b'} // Pink border color when focused
            _placeholder={{ color: '#fff' }}
            bg={'rgba(33, 33, 33, 0.3)'} // Transparent black background color
        />

    )
}


