import React from 'react'
import CustomPara from '../../Website/Paragraph/CustomPara';
import { Box, Stack } from '@chakra-ui/react';
import CustomHeading from '../../Website/Headings/CustomHeading';
import BorderButton from '../../Website/Buttons/BorderButton';

export default function NightlyReportList({ item }) {
    return (
        <>
            <Stack py={'5'} borderBottom={'1px solid #fff'} direction={'row'} alignItems={'center'} justifyContent={'space-between'}>
                <Box>
                    <CustomPara fontWeight={'900'} marginBottom={0} color={'brand.800'}>Date:</CustomPara>
                    <CustomHeading textAlign={'left'} color={'#fff'} fontSize={'15px'}>{String(item?.createdAt).split('T')[0]}</CustomHeading>
                </Box>
                <Box>
                    <CustomPara fontWeight={'900'} marginBottom={0} color={'brand.800'}>Type:</CustomPara>
                    <CustomHeading textAlign={'left'} color={'#fff'} fontSize={'15px'} textTransform={"capitalize"}>{item?.type}</CustomHeading>
                </Box>
                <Box>
                    <CustomPara fontWeight={'900'} marginBottom={0} color={'brand.800'}>Report:</CustomPara>
                    <a
                        style={{
                            color: '#fff',
                            backgroundColor: "rgb(213 63 140)",
                            width: '150px',
                            textAlign: 'center',
                            display: 'block',
                            borderRadius: '4px',
                            padding: '10px 5px'
                        }}
                        target='_blank'
                        download={true}
                        href={`https://nightdistrict.thewebtestlink.xyz${item?.url}`}>Export</a>
                </Box>
            </Stack>
        </>
    )
}
