import { Box, Link, Stack} from '@chakra-ui/react';
import React from 'react';
import CustomHeading from '../Headings/CustomHeading';
import CustomPara from '../Paragraph/CustomPara';
import Sub from '../../../assets/images/Banner/sub.jpg';
import { Link as ReactLink } from 'react-router-dom';

export default function PrivacyPolicyComponent() {

    const EmailLink = ({ color }) => {
        return (
          <a href="mailto:support@nightdistrict.co" style={{ color: color}}>
            support@nightdistrict.co
          </a>
        );
      };

  return (
    <>
     
        <Box w={'100%'} 
             bg={'#000'} 
             py={32} 
             display={'flex'}   
             flexDirection={'column'}
             alignItems={'center'}>
          <Box w={'100%'} maxW={'575px'} px={4}>
            <CustomHeading
              color={'#fff'}
              fontSize={'30px'}
              textAlign={'center'}
              fontWeight={'bold'}
            >
              Night District Privacy Policy
            </CustomHeading>
            
            <div style={{ color: 'white'}}> 
                <h2><strong>Introduction</strong></h2>
                <p>At Night District, we are committed to protecting the privacy and security of our partner’s (i.e., bar owners/operators) and their guest’s, (henceforth referred to as “users”) information. This Privacy Policy outlines how we collect, use, and safeguard personal data and other information when users interact with our platform. Our goal is to ensure transparency and build trust with our valued users. By accessing or using Night District, users consent to the practices described in this Privacy Policy.</p>
                <br />

                <h2><strong>Information Collected</strong></h2>
                <p>Night District is committed to ensuring the privacy and security of our users' data. As part of providing our services, we collect certain information our users to enhance their experience and improve our platform. This section outlines the types of data we collect and how we use it.</p>
                <br />
                <p>Personal Information: When guests and bar owners interact with Night District, we may collect certain personal information, such as names, email addresses, contact numbers, and profile pictures. This data is used to create and maintain user accounts, communicate with users, and personalize their experience on the app.</p>
                <br />
                <p>Location Data: To offer location-based services, Night District may collect and process location data when users enable location services on their devices. This information helps users discover nearby bars and venues and assists bar owners in understanding the popularity of their establishments.</p>
                <br />
                <p>Drink Preferences and Ordering History: Night District allows users to store their drink preferences and view their ordering history. This feature enhances the convenience of reordering favorite drinks and provides valuable insights to bar owners to offer tailored recommendations.</p>
                {/* Digital Driver's License: Night District provides the option for guests to upload their digital driver's license to verify their age for purchasing alcoholic beverages. We may collect and process this information for age verification purposes, ensuring compliance with legal requirements and promoting responsible alcohol consumption. Rest assured that the information is encrypted and securely stored, and access is limited to authorized personnel. Additionally, we may also use the information from your digital driver's license for marketing purposes. This includes tailoring our promotions, offers, and recommendations to better suit your nightlife preferences and interests. */}
                <br />
                <p>Usage Data: We collect anonymous usage data, including app interactions, browsing patterns, and feature usage. This information is used to analyze user behavior, identify popular features, and improve app functionality.</p>
                <br />
                <p>Communication Data: Night District may collect data related to user communications on the platform, such as messages sent between users and bar owners. This information helps us ensure smooth communication and address any customer service inquiries.</p>
                <br />
                <p>Device and Log Data: To optimize app performance and security, we collect device information, such as device type, operating system, and unique device identifiers. We also gather log data, including IP addresses, timestamps, and error reports, for troubleshooting purposes.</p>
                <br />
                <p>Payment Information: For in-app purchases and transactions, Night District may collect payment information, such as credit card details or payment provider data. All payment transactions are securely processed through trusted third-party payment gateways, and we do not store sensitive payment information.</p>
                <br />
                <p>Cookies and Similar Technologies: Night District uses cookies and similar technologies to enhance user experience, analyze usage patterns, and improve our services. These technologies allow us to remember user preferences, optimize app performance, and deliver relevant content.</p>
                <br />
                <p>It is important to note that while we strive to protect user data, Night District cannot guarantee the security of information transmitted over the internet. Users are responsible for safeguarding their login credentials and taking precautions to protect their personal data.</p>
                <br />
                <p>By using Night District, users consent to the collection, processing, and use of their data as described in this Privacy Policy. Users can review and update their information through the app settings.</p>
                <br />

                <h2><strong>Purpose of Data Collection</strong></h2>
                <p>At Night District, we collect and process data to provide an enhanced and personalized experience for our users, both guests and bar owners. The information we gather serves various purposes that enable us to deliver and improve our services effectively. Here are the key purposes for which we collect and use data:</p>
                <br />
                <p>Account Creation and Management: We collect personal information, such as names, email addresses, and profile pictures, to create and manage user accounts on Night District. This data allows users to access our platform and enjoy a seamless and customized experience.</p>
                <br />
                <p>Improved User Experience: By analyzing usage patterns and preferences, we tailor our platform to individual users' needs. This includes offering personalized drink recommendations, displaying nearby bars, and simplifying the ordering process for guests.</p>
                <br />
                <p>Communication and Support: Information collected enables effective communication between users and bar owners. We use this data to respond to inquiries, provide customer support, and ensure smooth interactions on our platform.</p>
                <br />
                <p>Location-Based Services: With users' consent, we collect location data to offer location-based services. This feature helps guests discover nearby bars and allows bar owners to understand their venues' popularity in different regions.</p>
                <br />
                <p>Analytics and Performance: Anonymous usage data and app interactions are analyzed to gain insights into user behavior, feature popularity, and overall app performance. This analysis guides us in improving Night District and enhancing user satisfaction.</p>
                <br />
                <p>Transaction and Payment Processing: When users make in-app purchases or transactions, we collect payment information to process payments securely through trusted third-party payment gateways.</p>
                <br />
                <p>Compliance and Legal Obligations: We may use data to comply with applicable laws, regulations, and legal processes, as well as to enforce our Terms of Service and protect the rights, privacy, and safety of Night District users.</p>
                <br />
                <p>Marketing and Promotions: With user consent, we may use certain data to deliver personalized promotions, offers, and marketing communications that align with individual preferences and interests.</p>
                <br />
                <p>Security and Fraud Prevention: Data collected aids in enhancing the security of our platform and detecting and preventing fraudulent activities to protect both users and Night District.</p>
                <br />
                <p>We never sell personal data to third parties. All data collection and processing adhere to applicable laws and regulations concerning privacy and data protection.</p>
                <br />

                <h2><strong>Data Sharing</strong></h2>
                <p>At Night District, we prioritize safeguarding your information and maintaining your privacy. We understand the importance of limiting data sharing and are committed to protecting your personal information. However, certain situations may require us to share data with trusted third parties to enhance our services and improve your overall experience. Here's how and why we may share your data:</p>
                <br />
                <p>With Bar Owners: As a core feature of Night District, we share certain data with bar owners to facilitate the ordering process and provide them with valuable insights into guest preferences and behaviors. This includes order history, drink preferences, and aggregated data related to bar performance.</p>
                <br />
                <p>Payment Processing Partners: To process payments and transactions on our platform, we share relevant payment information with trusted third-party payment processing partners. Rest assured that your financial data is handled securely and in compliance with industry standards.</p>
                <br />
                <p>Service Providers: Night District may collaborate with external service providers to improve our app's functionality and user experience. These partners are bound by strict confidentiality agreements and will only access and use the data necessary to perform their designated tasks.</p>
                <br />
                <p>Analytics and Performance: We may share anonymized and aggregated data with analytics partners to gain insights into user behavior, platform performance, and feature usage. This data helps us enhance Night District and make informed decisions to improve our services.</p>
                <br />
                <p>Legal Requirements and Protection: In certain circumstances, we may be required to disclose data in response to lawful requests from government authorities or to comply with legal processes. We may also share information to enforce our Terms of Service or protect the rights, safety, or security of Night District, our users, or others.</p>
                <br />
                <p>Business Transfers: In the event of a merger, acquisition, or other corporate transaction involving Night District, data may be transferred as part of the transaction. We will ensure that the receiving entity continues to protect your data in accordance with this Privacy Policy.</p>
                <br />
                <p>Your Consent: We will seek your explicit consent before sharing any personal data with third parties for purposes beyond those stated in this Privacy Policy.</p>
                <br />
                <p>Rest assured that any data shared will be subject to stringent confidentiality measures and used only for legitimate purposes. We will not sell, rent, or lease your personal information to any third parties.</p>
                <br />

                <h2><strong>Data Security Measures</strong></h2>
                <p>At Night District, we are committed to safeguarding your personal information and ensuring the security of your data. We employ robust measures to protect your data from unauthorized access, disclosure, alteration, or destruction. Here are the key security practices we implement:</p>
                <br />
                <p>Encryption: All data transmitted between your device and our servers is encrypted using industry-standard encryption protocols, such as Secure Socket Layer (SSL) or Transport Layer Security (TLS). This encryption ensures that your data remains confidential during transmission.</p>
                <br />
                <p>Secure Data Storage: Your data is stored on secure servers with access restricted to authorized personnel only. We use industry-standard security technologies and regularly update our systems to protect against potential vulnerabilities.</p>
                <br />
                <p>Access Controls: We implement strict access controls to limit access to your personal information. Only authorized employees with specific roles and responsibilities have access to your data, and they are bound by confidentiality agreements.</p>
                <br />
                <p>User Authentication: To prevent unauthorized access to your account, we employ various authentication mechanisms, including passwords, biometric identification (where available), and two-factor authentication.</p>
                <br />
                <p>Regular Security Audits: We conduct regular security audits and assessments to identify and address potential security risks. This helps us maintain the highest standards of data protection.</p>
                <br />
                <p>Monitoring and Incident Response: Our security team continuously monitors our systems for any unusual activities or potential security breaches. In the event of a data breach or security incident, we have established response procedures to promptly mitigate the impact and notify affected users as required by law.</p>
                <br />
                <p>Employee Training: We provide ongoing training and education to our employees to ensure they are well-informed about data security best practices and their role in maintaining the security of user data.</p>
                <br />
                <p>Third-Party Compliance: When we share data with third-party partners, we conduct thorough assessments to ensure they meet industry security standards and adhere to data protection regulations.</p>
                <br />
                <p>While we take significant measures to protect your data, it is essential to remember that no online platform can guarantee absolute security. We encourage you to take precautions to protect your own data, such as using strong passwords, keeping your account credentials confidential, and logging out of your account when using shared devices.</p>
                <br />
                <p>By using Night District, you acknowledge that data transmission over the internet has inherent risks, and you agree that Night District cannot be held liable for any unauthorized access to or disclosure of your data.</p>
                <br />

                <h2><strong>User Rights</strong></h2>
                <p>At Night District, we respect your privacy and understand the importance of providing you with control over your personal data. As a user, you have certain rights regarding the information we collect and process. We are committed to upholding these rights and ensuring your data protection. Here are your key rights as a Night District user:</p>
                <br />
                <p>Access and Rectification: You have the right to access the personal data we hold about you. If any of your information is inaccurate or incomplete, you can request that we correct or update it.</p>
                <br />
                <p>Data Portability: You have the right to receive a copy of your personal data in a structured, commonly used, and machine-readable format. This allows you to transfer your data to another service, where technically feasible.</p>
                <br />
                <p>Withdrawal of Consent: If you have previously provided consent for the processing of your personal data, you have the right to withdraw that consent at any time. This will not affect the lawfulness of any processing we conducted based on your consent before its withdrawal.</p>
                <br />
                <p>Objection to Processing: You can object to the processing of your personal data if you believe it is being processed unlawfully or without a legitimate basis. We will review your objection and cease processing your data unless we have compelling legitimate grounds to continue.</p>
                <br />
                <p>Erasure (Right to be Forgotten): You have the right to request the deletion of your personal data from our systems, subject to certain legal obligations or legitimate interests that require us to retain certain information.</p>
                <br />
                <p>Restriction of Processing: You can request the restriction of processing your personal data in specific circumstances, such as when you contest the accuracy of the data or the lawfulness of its processing.</p>
                <br />
                <p>Complaints: If you have concerns about how we handle your personal data, please contact us, and we will endeavor to address your concerns. You also have the right to lodge a complaint with the relevant data protection authorities.</p>
                <br />
                <p>Exercising Your Rights: To exercise any of your rights listed above or to seek further information about how we process your data, please contact us using the contact details provided in this Privacy Policy. We will respond to your requests within the timeframes required by applicable data protection laws.</p>
                <br />
                <p>Third-Party Data: If you provide us with personal data about others, you must ensure that you have the appropriate consent or legal basis to do so and that you inform them about how their data will be processed.</p>
                <br />
                <p>We are committed to processing your data in accordance with applicable data protection laws and respecting your rights as a Night District user. Your trust is essential to us, and we strive to provide you with the transparency and control you deserve.</p>
                <br />

                <h2><strong>Legal Basis for Data Processing</strong></h2>
                <p>At Night District, we process your personal data based on various legal grounds, as required by applicable data protection laws. The legal basis for our data processing activities may include:</p>
                <br />
                <p>Contractual Necessity: In certain instances, we process your personal data to fulfill our contractual obligations and provide you with access to our services.</p>
                <br />
                <p>Consent: Where required by law, we will seek your explicit consent to process specific categories of personal data. You have the right to withdraw your consent at any time, as explained in the "User Rights" section of this Privacy Policy.</p>
                <br />
                <p>Legitimate Interests: We may process your personal data based on our legitimate interests or those of third parties, provided that such interests are not overridden by your privacy rights and fundamental freedoms.</p>
                <br />
                <p>Legal Obligations: We may process your personal data to comply with applicable legal obligations, such as tax reporting or responding to lawful requests from public authorities.</p>
                <br />
                <p>Vital Interests: In exceptional circumstances, we may process your personal data to protect vital interests, such as in situations where your life or physical well-being is at risk.</p>
                <br />
                <p>Public Interest: In certain cases, we may process your personal data in the public interest, such as for research, statistics, or public health purposes.</p>
                <br />
                <p>Protection of Rights: We may process your personal data to protect our rights, property, or safety, or the rights, property, or safety of others.</p>
                <br />
                <p>We only process personal data when there is a lawful basis for doing so, and we ensure that our data processing activities align with the purposes for which the data was collected. We take appropriate measures to safeguard your privacy and uphold your rights throughout the data processing lifecycle.</p>
                <br />

                <h2><strong>Updates to the Policy</strong></h2>
                <p>At Night District, we are committed to continuously improving our services and enhancing your privacy protection. As a result, this Privacy Policy may be subject to periodic updates and revisions. We encourage you to review this policy regularly to stay informed about how we collect, use, and protect your personal data.</p>
                <br />
                <p>When we make material changes to this Privacy Policy, we will notify you through prominent means, such as by posting a notice on our website or sending you a direct communication. By continuing to use Night District's services after the updated Privacy Policy becomes effective, you signify your acceptance of the revised terms.</p>
                <br />
                <p>We understand the importance of transparency, and we will keep a record of previous versions of this Privacy Policy, which you can access upon request. If you have any questions or concerns regarding the updates or revisions to this policy, please contact us using the contact details provided in this Privacy Policy.</p>
                <br /> 

                <h2><strong>Contact Information</strong></h2>
                <p>If you have any questions, concerns, or inquiries related to this Privacy Policy or the privacy practices at Night District, please feel free to contact us at <EmailLink color="#dc0b9b" />.</p>
                <br /> 
                <p>We are dedicated to addressing your privacy concerns promptly and providing you with clear and comprehensive information regarding our data processing practices. Your privacy is important to us, and we are committed to maintaining open and transparent communication with our users.</p>
                <br /> 
                <p>We encourage you to reach out to us if you have any questions about the data we collect, how it is used, or if you wish to exercise your privacy rights as described in this Privacy Policy. Our team will strive to respond to your inquiries in a timely manner and ensure that your privacy is protected and respected.</p>
                <br /> 
        </div>
          </Box>
        </Box>
      
    </>
  )
}


