import { Box, Link, Stack } from '@chakra-ui/react';
import React from 'react';
import CustomHeading from '../Headings/CustomHeading';
import CustomPara from '../Paragraph/CustomPara';
import Sub from '../../../assets/images/Banner/sub.jpg';
import { Link as ReactLink } from 'react-router-dom';

export default function TermsSummaryComponent() {

    const EmailLink = ({ color }) => {
        return (
          <a href="mailto:support@nightdistrict.co" style={{ color: color}}>
            support@nightdistrict.co
          </a>
        );
      };

  return (
    <>
      
        <Box w={'100%'} 
             bg={'#000'} 
             py={32} 
             display={'flex'}   
             flexDirection={'column'}
             alignItems={'center'}>
         <Box w={'100%'} maxW={'575px'} px={4}>
            <CustomHeading
              color={'#fff'}
              fontSize={'30px'}
              textAlign={'center'}
              fontWeight={'bold'}
            >
              Night District Guest Terms of Service
            </CustomHeading>
            <div style={{ color: 'white'}}> 

            <h2><strong>Introduction</strong></h2>
            <p>These Terms of Service outline the rules and guidelines that govern your use of the Night District app, its services, and all related features. At Night District, we prioritize your privacy and security. Our commitment to transparency and user empowerment is embodied in our Privacy Policy, which complements these Terms of Service. We encourage you to review the Privacy Policy to understand how we collect, use, and protect your personal information.</p>
            <br />
            <p>
            This Terms of Service agreement serves as a legal contract between you, Night District user, hereafter referred to as “Guest”, and Night District. Please take a moment to familiarize yourself with its provisions, as they govern your use of Night District.
            </p>
            <br />
            
            <h2><strong>Acceptance of Terms</strong></h2>
            <p>By accessing and creating an account with Night District, you are entering into a legally binding agreement with us. Your continued use of the app signifies your acceptance and agreement to abide by these terms.</p>
            <br />
            
            <h2><strong>Ordering with Night District</strong></h2>
            <p>Night District does not sell, offer to sell, or solicit sales of alcohol: our Platform empowers you to explore and discover alcohol and other products available for purchase from licensed alcohol retailers who have partnered with Night District ("Partners"). The purpose of our Platform is to help you conveniently find and order products from authorized sellers. Our intention is not to enable any improper inducements by alcohol manufacturers, importers, suppliers, wholesalers, or distributors to retailers of alcoholic beverages, nor to facilitate any exclusionary practices by alcohol beverage licensees.</p>
            <br />
            <p>As you use our Platform to browse for products, you will see their availability, pricing, and other relevant information based on your location and the details provided by our Partners that serve your area. You also have the option to sort and filter results based on specific search criteria. Please note that the availability of our Platform's services may be limited or unavailable in certain locations due to local regulations or other restrictions.</p>
            <br />

            <h2><strong>User Commitments</strong></h2>
            <p>By accessing and using Night District's mobile app and services, you agree to abide by the following user responsibilities:</p>
            <br />
            <p>Age Requirement: You must be at least 21 years of age to order alcoholic beverages through Night District. We strictly adhere to all local, state, and federal laws related to the purchase and consumption of alcohol. It is your responsibility to ensure that you meet the legal drinking age in your area.</p>
            <br />
            <p>Accurate Information: When registering on Night District, you agree to provide accurate and up-to-date information, including your name, date of birth, and any other details required for account creation. You are solely responsible for maintaining the confidentiality of your account and keeping your login credentials secure.</p>
            <br />
            <p>Compliance with Laws: While using Night District, you must comply with all applicable laws and regulations, including those related to alcohol consumption, public behavior, and user content. Any illegal or unauthorized use of the app is strictly prohibited.</p>
            <br />
            <p>Responsible Consumption: If you choose to order alcoholic beverages through Night District, we remind you to drink responsibly. It is essential to be aware of your alcohol consumption and adhere to the legal limits in your jurisdiction.</p>
            <br />
            <p>Prohibited Content: You may not upload content on Night District that is offensive, defamatory, harmful, or violates the rights of others. Additionally, you may not use the platform for spamming, advertising, or engaging in any other harmful activities.</p>
            <br />
            <p>Respect for Others: Night District values diversity and inclusivity. You are expected to treat fellow users, bar staff, and Night District's team members with respect and courtesy.</p>
            <br />
            <p>Report Inappropriate Behavior: If you encounter any inappropriate behavior, harassment, or violations of our Terms of Service within the app, we encourage you to report such incidents promptly using the provided reporting mechanisms.</p>
            <br />
            <p>Compliance with Venue Policies: When visiting partner venues listed on Night District, you must adhere to their individual policies and guidelines, including dress codes, entry requirements, and house rules.</p>
            <br />
            <p>Timely Arrival at the Bar: As part of enhancing your experience, we request that you make your way to the bar within 15 minutes upon receiving a push notification that your drink is ready for pickup. If you do not collect your drink within this timeframe, for reasons such as delays or interruptions, please note that your drink could be discarded, and no refund will be issued. Your timely response not only ensures the quality of your order but also contributes to the smooth operation of our service for all patrons.</p>
            <br />
            <p>Consent to Data Collection: By using Night District, you acknowledge and consent to the collection, use, and processing of your data as described in our Privacy Policy.</p>
            <br />
            <p>Account Security: You are responsible for maintaining the security of your Night District account. Please do not share your password, give access to your Night District account to others, or transfer your account to anyone else without our explicit permission.</p>
            <br />
            
            <h2><strong>Who Can Use Night District</strong></h2>
            <p>Night District is designed to be an inclusive platform accessible to a wide range of users. However, there are certain limitations on who can use Night District. You cannot use Night District if:</p>
            <br />
                <ul style={{ paddingLeft: '20px' }}>
                    <li>You are under 21 years old.</li>
                    <li>Your account has been previously disabled for violating our Terms of Service or any other terms and policies that apply to Night District. If your account has been disabled for violations, you agree not to create another account without our explicit permission. Permission to create a new account is provided at our sole discretion and does not imply that the previous disciplinary action was unwarranted.</li>
                    <li>You are prohibited from using our products, services, or software under applicable laws.</li>
                </ul>
            <br />
            <p>At Night District, we prioritize safety, compliance with laws, and the responsible use of our platform. By using Night District, you agree to adhere to our Terms of Service and respect the guidelines that promote a positive and inclusive nightlife community. We welcome all eligible users to join Night District and experience the best of nightlife in a responsible and enjoyable manner.</p>
            <br />

            <h2><strong>Data Collection</strong></h2>
            <p>Your privacy is of utmost importance to us, and we are committed to ensuring that your personal data is treated with the utmost care and protection. This Consent to Data Collection outlines how we collect, use, and store your information while you use Night District's mobile app and services. By using Night District, you consent to the collection and processing of your data as described below:</p>
            <br />
            <p>Basic Information: By using Night District, you grant us permission to use your name, profile picture, and information about your interactions with ads, sponsored, or commercial content displayed across our platform. This permission allows us to enhance your user experience and provide you with personalized content and offerings without any compensation to you.</p>
            <br />
            <p>Drink Order and Preferences: We store your drink order history to help you keep track of your favorite drinks and previous orders for your convenience. Understanding your drink preferences allows us to provide personalized recommendations and enhance your nightlife experience.</p>
            <br />
            <p>Location Data: With your consent, we collect location data to display nearby bars and nightclubs, enabling you to explore and enjoy the best nightlife experiences in your area. We also collect information on your favorite bar information that helps us curate relevant content and offers that align with your preferences and enhance your visits to your preferred venues.</p>
            <br />
            <p>Email and Notifications: We may use your email address to send you important updates, promotions, and news related to Night District. You have the option to manage your notification preferences in the app's settings.</p>
            <br />
            <p>Aggregated and Anonymized Data: We may use aggregated and anonymized data for analytical purposes, improving our services, and understanding user trends. This data does not identify you personally and is used solely for statistical purposes. Please note that while we take all necessary measures to safeguard your data, no online platform can guarantee absolute security. It is your responsibility to protect your Night District account information, including your login credentials and password.</p>
            <br />
            <p>Your privacy matters to us, and we are dedicated to maintaining your trust and confidence in using Night District. If you have any questions or concerns regarding our data collection practices, please feel free to contact us at <EmailLink color="#dc0b9b" />. By continuing to use Night District, you signify your consent to this data collection and acknowledge that you have read and understood our Privacy Policy, which further elaborates on how we handle your personal data.</p>
            <br />

            <h2><strong>Intellectual Property</strong></h2>
            <p>Respect the intellectual property rights of Night District and others. Unauthorized use may lead to account termination.</p>
            <br />
            <p>Night District respects the intellectual property rights of others and expects our users to do the same. We are committed to protecting the copyrights, trademarks, and other intellectual property of Night District and third parties. This Intellectual Property section outlines the guidelines and responsibilities related to intellectual property while using Night District's services:</p>
            <br />
            <p>Night District's Intellectual Property: All content and materials on the Night District platform, including but not limited to software, designs, graphics, user interfaces, text, logos, images, and videos, are the property of Night District and are protected by intellectual property laws and regulations. Users are prohibited from copying, reproducing, distributing, or using any Night District intellectual property without our explicit permission.</p>
            <br />
            <p>User-Generated Content: As a user of Night District, you may contribute user-generated content, including photos, reviews, comments, and other materials. By submitting user-generated content, you grant Night District a non-exclusive, worldwide, royalty-free, and fully sublicensable license to use, reproduce, modify, adapt, publish, translate, distribute, and display such content on the Night District platform. This license allows us to showcase your contributions and enhance the Night District experience for all users.</p>
            <br />
            <p>Respecting Third-Party Intellectual Property: At Night District, we value and respect the intellectual property rights of third parties, including bars and nightclubs. Users are not allowed to post, share, or transmit content that infringes on the copyrights, trademarks, or other proprietary rights of others. However, you are permitted to share third-party content via Night District to other social media platforms.</p>
            <br />
                <ul style={{ paddingLeft: '20px' }}>
                    <li>A description of the copyrighted work or trademark claimed to have been infringed.</li>
                    <li>Sufficient evidence of the intellectual property right that has allegedly been infringed.</li>
                    <li>Your contact information, including your name, address, telephone number, and email address.</li>
                    <li>A statement that you have a good-faith belief that the disputed use is not authorized by the copyright or trademark owner, its agent, or the law.</li>
                    <li>A statement, made under penalty of perjury, that the information in your notice is accurate and that you are the copyright or trademark owner or authorized to act on the owner's behalf.</li>
            </ul>
            <br />
            <p>By using Night District, you agree to abide by these Intellectual Property guidelines and respect the intellectual property rights of Night District and third parties. We encourage all users to create and contribute content responsibly and lawfully, enhancing the Night District community while upholding the principles of intellectual property protection. Any unauthorized use, reproduction, or distribution of Night District's intellectual property may result in termination of your access to the platform and may also lead to legal consequences. </p>
            <br />

            <h2><strong>ID Verification and Safety Measures</strong></h2>
            <p>The responsibility of verifying identification and age and ensuring the safe and appropriate delivery of alcohol lies with our partners. They may require you to provide a valid form of photo identification with your birthdate at the time of pick-up. In cases where your age or identity cannot be verified, or if any safety concerns arise (for example, you appear to be under the legal drinking age, or the situation is deemed unsafe), our Partner may decline to furnish you with alcohol. In situations where we suspect fraudulent or unlawful activity related to your order, we may inform our Partner and temporarily suspend your use of Night District.</p>
            <br />
            
            <h2><strong>Limitation of Liability</strong></h2>
            <p>Please read this section carefully as it limits the liability of Night District and its affiliates for certain circumstances. By using Night District, you agree to the terms outlined in this section.</p>
            <br />
            <p>Service Availability: Night District strives to provide a seamless and reliable service. However, we cannot always guarantee uninterrupted access to our platform. We may need to perform maintenance, updates, or face technical issues that could temporarily disrupt service. Night District shall not be liable for any inconvenience, loss, or damages arising from such temporary interruptions.</p>
            <br />
            <p>Third-Party Services and Content: Night District may contain links to third-party websites, applications, or services. These links are provided for your convenience, but Night District does not endorse, control, or take responsibility for the content, products, or services offered by these third parties. Any interaction with third-party services is done at your own risk, and Night District shall not be liable for any damages, losses, or disputes arising from such interactions.</p>
            <br />
            <p>User-Generated Content: Night District is a platform where guests and our partners (henceforth referred to as ‘users’) contribute, share, and offer content. We do not endorse or verify the accuracy, completeness, or legality of user-generated content. Users are solely responsible for their contributions, and Night District shall not be liable for any damages or claims arising from user-generated content.</p>
            <br />
            <p>Disclaimer of Warranties: Night District provides its services on an "as is" and "as available" basis, without any warranties, whether express or implied. We do not warrant that Night District will meet your requirements, be error-free, secure, or free from harmful components. Night District disclaims all warranties, including but not limited to warranties of merchantability, fitness for a particular purpose, and non-infringement.</p>
            <br />
            <p>Limitation of Liability: To the extent permitted by applicable law, Night District and its affiliates shall not be liable for any direct, indirect, incidental, special, consequential, or exemplary damages, including but not limited to damages for loss of profits, goodwill, data, or other intangible losses, arising out of or in connection with your use of Night District or its services. This limitation of liability applies whether the claim is based on contract, tort, strict liability, or any other legal theory, even if Night District has been advised of the possibility of such damages.</p>
            <br />
            <p>Indemnification: You agree to indemnify, defend, and hold harmless Night District, its affiliates, and their respective directors, officers, employees, and agents from and against all claims, liabilities, damages, losses, costs, or expenses (including reasonable attorneys' fees) arising from your use of Night District or your violation of these Terms of Service.</p>
            <br />
            <p>These limitations and disclaimers shall apply to the fullest extent permitted by applicable law. In no event shall Night District's total liability for all claims arising from or related to your use of Night District exceed the amounts you have paid to Night District, if any, for accessing our services.</p>
            <br />
           
            <h2><strong>Payment and Billing</strong></h2>
            <p>By using Night District's services, you agree to the following terms related to payments and billing:</p>
            <br />
            <p>Pricing and Payment: Although use of Night District is free for guests, Night District displays various services, features, and products offered from our partners, each with their respective prices. The pricing details are clearly displayed on our platform, and you agree to pay the specified fees associated with the services or products you choose to purchase.</p>
            <br />
            <p>Payment Methods: Night District accepts various payment methods, including credit cards, debit cards, and other electronic payment options like Google Pay and Apple Pay. By providing your payment information, you authorize us to charge the applicable fees to your chosen payment method.</p>
            <br />
            <p>Subscription Services: If you subscribe to any of Night District's subscription-based services, you agree to recurring payments according to the selected subscription plan. These payments will be automatically charged to your chosen payment method at the specified intervals unless you cancel your subscription.</p>
            <br />
            <p>Taxes and Fees: You are responsible for any applicable taxes, fees, or other charges imposed by relevant authorities in connection with your use of Night District's services. Such charges will be added to the total amount you are required to pay.</p>
            <br />
            <p>Billing Information: It is your responsibility to ensure that your billing information, such as credit card details, is accurate and up to date. Inaccurate or outdated billing information may result in a failure to process your payment and could lead to the suspension or termination of your account.</p>
            <br />
            <p>Refunds: Night District is not responsible for issuing refunds for unfulfilled orders. Night District acts as a platform to facilitate the ordering process, but the responsibility for fulfilling orders lies with the respective bars and nightclubs.</p>
            <br />
            <p>Automatic Renewal: Certain services offered by Night District may automatically renew at the end of their billing cycle. To avoid automatic renewal, you must cancel the service before the renewal date.</p>
            <br />
            <p>Invoice and Receipts: You will receive an invoice or receipt for each payment made to Night District. These documents will be provided to you electronically via email or can be accessed through your Night District account.</p>
            <br />
            <p>Disputes and Chargebacks: If you believe there is an error or discrepancy with any billing transaction, please contact our support team promptly to resolve the matter. Chargebacks or disputes initiated without contacting our support team may result in the suspension or termination of your account.</p>
            <br />
            <p>Termination of Paid Services: If you choose to terminate any paid service or subscription, you will continue to have access to the service until the end of the billing cycle. No prorated refunds will be provided for early termination.</p>
            <b />

            <h2><strong>Governing Law</strong></h2>
            <p>The Terms of Service ("Terms") for Night District shall be governed by and construed in accordance with the laws of the state of Kentucky, United States, without regard to its conflict of law principles.</p>
            <br />
            <p>In the event of any disputes, claims, or legal proceedings arising from or related to the use of Night District's services, you agree to submit to the exclusive jurisdiction of the courts located in Kentucky. This choice of jurisdiction is intended to facilitate a fair and efficient resolution of any issues that may arise between you and Night District.</p>
            <br />
            <p>By accessing or using Night District's services, you consent to the application of the laws of Kentucky to these Terms and any disputes that may arise. If any part of these Terms is found to be unenforceable or invalid by a court of competent jurisdiction, the remaining provisions shall remain in full force and effect.</p>
            <br />
            <p>This choice of governing law and jurisdiction is designed to provide a clear and predictable legal framework for the resolution of disputes and to promote a consistent interpretation of these Terms.</p>
            <br />
            
            <h2><strong>Modifications and Updates</strong></h2>
            <p>We're continuously working to enhance our services and features, and we may update these Terms of Service from time to time. Rest assured that any modifications will be communicated to you via in-app communication, and your continued use of the app after such changes will indicate your acceptance of the updated terms.</p>
            <br />

            <h2><strong>Termination of Accounts</strong></h2>
            <p>Night District reserves the right to suspend or terminate your account if you fail to comply with our Terms of Service or engage in any behavior that may be harmful to the community or the app. Night District also reserves the right to suspend or terminate the accounts of users who repeatedly infringe on the intellectual property rights of others or violate these Intellectual Property guidelines.</p>
            <br />
            
            <h2><strong>Dispute Resolution</strong></h2>
            <p>In the event of any disputes, claims, or controversies arising out of or relating to these Terms or the use of Night District's services, both you and Night District agree to make reasonable efforts to resolve the matter through good-faith negotiation and communication.</p>
            <br />
            <p>Informal Resolution: If you have any concerns or disputes regarding your use of Night District's services, we encourage you to first contact our customer support team at [contact email or support center]. We will diligently work with you to address and resolve your concerns in a fair and timely manner.</p>
            <br />
            <p>Mediation: If the matter remains unresolved through informal negotiation, both parties agree to consider non-binding mediation as an alternative dispute resolution method. The mediator shall be selected jointly, and the mediation shall be conducted in accordance with the rules and procedures of [mediation service or organization], as agreed upon by both parties.</p>
            <br />
            <p>Arbitration: If resolution through mediation is not successful, any unresolved disputes, claims, or controversies shall be settled by binding arbitration in accordance with the rules and procedures of the American Arbitration Association (AAA), and judgment upon the award rendered by the arbitrator(s) may be entered in any court having jurisdiction thereof.</p>
            <br />
            <p>Waiver of Class Action: You and Night District waive any right to participate in a class or representative action in arbitration or in any court. You also agree that you have the right to opt-out of arbitration within 30 days of the date you first agreed to these Terms by providing written notice to <EmailLink color="#dc0b9b" />.</p>
            <br />
            <p>Judicial Proceedings: Notwithstanding the above, either party may seek injunctive or other equitable relief to protect its intellectual property rights, privacy, or confidential information, or to prevent unauthorized access or use of Night District's services.</p>
            <br />
            <p>Applicable Law: This Dispute Resolution section shall be governed by and interpreted in accordance with the laws of the state of Kentucky, United States, without regard to its conflict of law principles.</p>
        </div>

          </Box>
        </Box>
        
        {/* Night District Partner Terms of Service */}
        <Box
          w={'100%'}
          bgSize={'cover'}
          bgRepeat={'no-repeat'}
          backgroundImage={Sub}
          marginLeft={'0 !important'}
          py={32}
          display={'flex'}   
          flexDirection={'column'}
          alignItems={'center'}
        >
         <Box w={'100%'} maxW={'575px'} px={4}>
            <CustomHeading
              color={'#fff'}
              fontSize={'30px'}
              textAlign={'center'}
              fontWeight={'bold'}
            >
              Night District Partner Terms of Service
            </CustomHeading>
            <div style={{ color: 'white'}}> 

            <h2><strong>Introduction</strong></h2>
            <p>This Terms of Service agreement outlines the terms and conditions governing the use of Night District's platform by bar owners and operator. At Night District, we prioritize your privacy and security. Our commitment to transparency and partner empowerment is embodied in our Privacy Policy, which complements these Terms of Service. We encourage you to review the Privacy Policy to understand how we collect, use, and protect your personal information.</p>
            <br />
            <p>
            This Terms of Service agreement serves as a legal contract between you, the bar owner or operator, hereafter referred to as “Partner”, and Night District. Please take a moment to familiarize yourself with its provisions, as they govern our partnership and ensure a seamless and successful collaboration.</p>
            <br />
            
            <h2><strong>Acceptance of Terms</strong></h2>
            <p>By accessing and creating an account with Night District, you are entering into a legally binding agreement with us. Your continued use of the app signifies your acceptance and agreement to abide by these terms.</p>
            <br />
            
            <h2><strong>Partner Commitments</strong></h2>
            <p>As a valued partner utilizing Night District's platform, you commit to the following terms and responsibilities to ensure a positive and fruitful partnership:</p>
            <br />
            <p>Accurate Information: You agree to provide accurate and up-to-date information about your establishment during the registration process. This includes the name, location, contact details, and any other relevant details related to your bar.</p>
            <br />
            <p>Compliance with Laws: You commit to adhering to all applicable laws, regulations, and licensing requirements governing the sale and distribution of alcoholic beverages and other products offered through Night District. This includes ensuring that your bar operates within legal age restrictions and complies with any other industry-specific regulations.</p>
            <br />
            <p>Product Availability: You will ensure that the products listed on Night District's platform are available for purchase and accurately represented in terms of pricing, ingredients, and descriptions. If products listed on Night District are to go out of stock, you will mark product as such via Night District mobile application or website. </p>
            <br />
            <p>Data Privacy: You will uphold the highest standards of data privacy and security for your customers. Any personal or sensitive information collected through Night District will be handled in accordance with relevant data protection laws and not shared with any unauthorized parties.</p>
            <br />
            <p>Integrity of Reviews: You acknowledge that customer reviews and ratings on Night District play a vital role in fostering trust and credibility. Therefore, you commit to refraining from any attempt to manipulate or influence reviews to maintain the integrity of the platform.</p>
            <br />
            <p>Customer Service: You will prioritize excellent customer service and promptly address any inquiries, complaints, or feedback received from patrons through Night District.</p>
            <br />
            <p>Payment and Billing: You agree to the financial terms specified in our agreement, including the revenue-sharing model where Night District takes a cut of orders placed through the platform and monthly reoccurring fee. All payments will be processed accurately and in a timely manner.</p>
            <br />
            <p>Brand Usage: Night District may use your establishment's brand name, logo, and other promotional materials for marketing and advertising purposes to promote your presence on the platform. By using Night District, you grant us permission to use such materials.</p>
            <br />
            <p>Integration and Cooperation: If you choose to integrate Night District with your existing Point of Sale (POS) system or other software, you commit to providing necessary information and cooperating with our technical team to facilitate a seamless integration.</p>
            <br />
            <p>Your commitment to these user responsibilities is instrumental in creating a thriving nightlife ecosystem and enhancing the overall experience for patrons.</p>
            <br />
            
            <h2><strong>Who Can Use Night District</strong></h2>
            <p>Night District's platform is designed to empower our partners to leverage technology and enhance the customer experience at your establishment. To utilize Night District's services and benefit from its features, you must meet the following eligibility criteria:</p>
            <br />
                <ul style={{ paddingLeft: '20px' }}>
                    <li>Legal Entity: You must be the authorized owner or operator of a legally established bar or nightclub that is operating within the laws and regulations of your respective jurisdiction. Official documentation, such as business licenses, permits, or legal registration documents, proving your ownership or employment of the establishment will be required during the registration process.</li>
                    <li>Age Requirement: To use Night District's services, you must be at least 21 years old or the age of majority in your jurisdiction, whichever is higher.</li>
                    <li>Consent and Authority: By agreeing to these terms, you represent that you have the necessary consent, authority, and legal rights to enter into this agreement on behalf of your bar or nightclub.</li>
                    <li>Termination of Accounts: Night District reserves the right to suspend or terminate the accounts of bar owners who violate these terms or engage in any activity that is harmful to the Night District community or reputation.</li>
                    <li>Compliance with Laws: You must agree to abide by all applicable laws, rules, and regulations governing the sale and distribution of alcoholic beverages and other products through Night District. This includes complying with age restrictions and any other industry-specific requirements.</li>
                </ul>
            <br />
            <p>Night District aims to create a trusted platform that promotes seamless interactions between bar owners and their customers. By meeting these eligibility criteria, you can take advantage of the innovative features offered by Night District to enhance the overall experience at your bar or nightclub.</p>
            <br />

            <h2><strong>Service Agreement</strong></h2>
            <p>The scope of services provided by Night District is designed to revolutionize the nightlife experience for both guests and bar owners. As a comprehensive platform, Night District offers a wide range of features and functionalities tailored to meet the specific needs of bar owners and enhance their business operations.</p>
            <br />
            <p>Mobile Ordering: Night District enables bar owners to implement seamless mobile ordering, allowing guests to browse your drink menu, place orders, and make payments directly from their smartphones. </p>
            <br />
            <p>Drink Queue Management: With Night District's intuitive dashboard, bartenders can effectively manage the drink queue and track real-time orders. This feature helps optimize bar operations, reducing order processing time and ensuring timely delivery of drinks to guests.</p>
            <br />
            <p>Analytics and Insights: Night District empowers bar owners with valuable analytics and insights into customer behavior, preferences, and buying patterns. By leveraging this data, bar owners can make informed decisions, tailor their offerings, and devise targeted marketing strategies to enhance customer satisfaction.</p>
            <br />
            <p>Promotional Tools: Night District provides bar owners with innovative promotional tools to drive foot traffic and increase customer engagement. This includes personalized promotions, loyalty programs, and exclusive offers, all of which can be easily managed through the platform.</p>
            <br />
            <p>Aggregated and Anonymized Data: We may use aggregated and anonymized data for analytical purposes, improving our services, and understanding user trends. This data does not identify you personally and is used solely for statistical purposes. Please note that while we take all necessary measures to safeguard your data, no online platform can guarantee absolute security. It is your responsibility to protect your Night District account information, including your login credentials and password.</p>
            <br />

            <h2><strong>Age Verification Responsibility</strong></h2>
            <p>As a Partner of Night District's platform, you acknowledge and agree that it is your sole responsibility to verify the legal drinking age and age of guests picking up alcoholic beverages from your establishment. This includes requiring valid photo identification with birthdate confirmation upon entry to your establishment and/or at the time of drink pickup. You must ensure that only individuals of legal drinking age as defined by local laws are provided with alcohol.</p>
            <br />
            <p>Night District assumes no liability for any failure on your part to verify guests ages or for any incidents that may arise due to the improper furnishing of alcohol to individuals who are underage or not of legal age to consume alcoholic beverages. By using Night District's platform, you affirm your commitment to adhering to all applicable laws and regulations governing the sale and distribution of alcoholic beverages and the responsible verification of guests ages.</p>
            <br />

            <h2><strong>Data Collection</strong></h2>
            <p>Night District values the privacy and security of our bar owners and their guests’ information. As part of providing our services, we collect certain data to ensure the smooth functioning of the platform and deliver a personalized experience. We are committed to being transparent about the data we collect and how it is used. The following outlines the types of data we collect from our bar owners:</p>
            <br />
            <p>Account Information: When bar owners sign up for Night District, we collect basic account information, including the business name, address, contact details, and payment information. This data is essential for creating and maintaining your Night District account.</p>
            <br />
            <p>Menu and Inventory Data: We collect information about your drink menu, pricing, and inventory to facilitate mobile ordering and drink queue management. This data helps us ensure that the correct drinks are displayed to your customers and allows you to efficiently manage your inventory.</p>
            <br />
            <p>Order Data: Night District collects data related to customer orders, including order details, order history, and transaction information. This data is crucial for processing orders, providing customer support, and generating analytics to help you make data-driven business decisions.</p>
            <br />
            <p>Analytics Data: We gather anonymous and aggregated data on customer behavior, such as order trends, ticket purchases, popular drinks, and peak hours. This information is used to generate valuable insights and performance reports, enabling you to optimize your bar's operations and marketing strategies.</p>
            <br />
            <p>Communication Data: We collect data from communications between bar owners and Night District, such as emails and support requests. This information helps us respond to your inquiries and provide customer support effectively.</p>
            <br />
            <p>Location Data: Night District may collect location data from bar owners to offer location-based features and services. This data helps us provide customers with relevant information based on their proximity to your bar.</p>
            <br />
            <p>Feedback and Reviews: If bar owners provide feedback or reviews about Night District's services, we may collect and analyze this data to continuously improve our platform and offerings.</p>
            <br />
            <p>Please rest assured that Night District takes data protection seriously. We implement robust security measures to safeguard your data from unauthorized access or disclosure. Night District does not sell or share your data with third parties for their marketing purposes. For more information on how we handle data collection and usage, please refer to our Privacy Policy.</p>
            <br />
            
            <h2><strong>Intellectual Property</strong></h2>
            <p>Night District respects the intellectual property rights of our bar owners and expects the same respect in return. By agreeing to these Terms of Service, you acknowledge that Night District owns all intellectual property rights related to the platform, including but not limited to software, designs, logos, trademarks, and any proprietary information.</p>
            <br />
            <p>Night District's Intellectual Property: As a partner of Night District, you are granted a non-exclusive, non-transferable, and revocable license to access and use the platform strictly for your business purposes. This license does not grant you any ownership rights or permission to copy, modify, distribute, or sell any of Night District's intellectual property without our prior written consent.</p>
            <br />
            <p>Partner Intellectual Property: In turn, you retain all rights, title, and interest in any intellectual property that you provide or upload to Night District. By sharing content on the platform, you grant Night District a limited license to use, display, reproduce, and distribute that content solely for the purpose of providing our services to you and your guests.</p>
            <br />
            <p>Reporting Intellectual Property Infringements: Night District takes intellectual property rights seriously and will respond to valid claims of infringement. If you believe that any content on Night District infringes your intellectual property rights or the rights of someone you represent, please contact us at <EmailLink color="#000" /> with the following information:</p>
            <br />
                <ul style={{ paddingLeft: '20px' }}>
                    <li>A description of the copyrighted work or trademark claimed to have been infringed.</li>
                    <li>Sufficient evidence of the intellectual property right that has allegedly been infringed.</li>
                    <li>Your contact information, including your name, address, telephone number, and email address.</li>
                    <li>A statement that you have a good-faith belief that the disputed use is not authorized by the copyright or trademark owner, its agent, or the law.</li>
                    <li>A statement, made under penalty of perjury, that the information in your notice is accurate and that you are the copyright or trademark owner or authorized to act on the owner's behalf.</li>
                </ul>
            <br />
            <p>By using Night District, you agree to abide by these Intellectual Property guidelines and respect the intellectual property rights of Night District and third parties. We encourage all users to create and contribute content responsibly and lawfully, enhancing the Night District community while upholding the principles of intellectual property protection. Any unauthorized use, reproduction, or distribution of Night District's intellectual property may result in termination of your access to the platform and may also lead to legal consequences. </p>
            <br />
           
            <h2><strong>Limitation of Liability</strong></h2>
            <p>Night District strives to provide a reliable and efficient platform for bar owners to enhance their business operations. However, it is essential to understand that the use of our services is at your own risk, and Night District, including its officers, employees, and affiliates, shall not be liable for any direct, indirect, incidental, consequential, or special damages arising from or in any way related to your use of the platform.</p>
            <br />
            <p>Night District does not assume responsibility for:</p>
            <br />
                <ul style={{ paddingLeft: '20px' }}>
                    <li>Technical failures, interruptions, or disruptions in the operation of the platform, including but not limited to system </li>
                    <li>Unauthorized access to or alteration of your account information, data, or content stored on Night District.</li>
                    <li>Content provided by users or third parties, including any offensive, harmful, or inaccurate content.</li>
                    <li>Any interaction or transaction between bar owners and their customers facilitated through Night District.</li>
                    <li>Delays or inaccuracies in the information or content provided on Night District.</li>
                    <li>Any actions taken based on the information or content available on Night District.</li>
                    <li>Any loss of profits, revenue, data, or business opportunities resulting from the use of Night District.</li>
                    <li>Any interaction with third-party services is done at your own risk, and Night District shall not be liable for any damages, losses, or disputes arising from such interactions.</li>
                </ul>
            <br />
            <p>Furthermore, Night District does not endorse or guarantee the accuracy, quality, or reliability of the services provided by bar owners or their customers through the platform. </p>
            <br />
            <p>To the maximum extent permitted by law, Night District disclaims all warranties, whether express or implied, including but not limited to warranties of merchantability, fitness for a particular purpose, and non-infringement.</p>
            <br />
            <p>Some jurisdictions do not allow the exclusion or limitation of certain types of damages, so the above limitations may not apply to you. In such cases, our liability will be limited to the fullest extent permitted by applicable law.</p>
            <br />
            <p>By using Night District's services, you agree to release and hold harmless Night District, its members, employees, and affiliates from any claims, demands, or damages, whether known or unknown, arising out of or in connection with your use of the platform.</p>
            <br />

            <h2><strong>Payment and Billing</strong></h2>
            <p>Night District offers bar owners a monthly subscription-based payment model to access and utilize our platform's advanced features. By subscribing to our services, you agree to the following terms related to payment and billing:</p>
            <br />
            <p>Monthly Subscription: As a bar owner, you will be billed monthly or yearly, depending on the plan chosen, for the use of Night District's services. The subscription fee will be automatically charged to the payment method you provided upon signing up for the service.</p>
            <br />
            <p>Payment Method: You are responsible for providing accurate and up-to-date payment information, such as credit card details, necessary for processing your monthly subscription payment. By providing your payment information, you authorize Night District to charge the applicable subscription fee to your chosen payment method.</p>
            <br />
            <p>Recurring Payments: Your subscription will automatically renew monthly or yearly unless you choose to cancel your subscription before the next billing cycle. By opting for the monthly subscription, you agree to recurring payments until you initiate the cancellation process.</p>
            <br />
            <p>Billing Cycle: The monthly billing cycle starts on the date you subscribed to Night District's services and continues every 30 days thereafter. The yearly billing cycle starts on the date you subscribed to Night District's services and continues every 365 days thereafter. Your payment will be processed at the beginning of each billing cycle.</p>
            <br />
            <p>Billing Amount: The subscription fee will be clearly communicated to you before you finalize your subscription. It may vary depending on the plan and features you choose. Any changes to the subscription fee will be notified to you in advance.</p>
            <br />
            <p>Automatic Renewal: Certain services offered by Night District may automatically renew at the end of their billing cycle. To avoid automatic renewal, you must cancel the service before the renewal date.</p>
            <br />
            <p>Cancellation: You can cancel your subscription at any time by accessing your account settings on Night District's platform. If you cancel during a billing cycle, your access to the platform's premium features will continue until the end of that billing cycle, and your subscription will not renew for the following month.</p>
            <br />
            <p>Refunds: Night District does not provide refunds for any unused portion of a subscription period, even if you choose to cancel your subscription before the end of the billing cycle. Refunds will only be provided in cases where required by applicable law or at Night District's sole discretion.</p>
            <br />
            <p>Failed Payments: In the event of a failed payment or if we are unable to process your subscription fee, your access to the premium features of Night District may be temporarily suspended until the payment is successfully processed.</p>
            <br />
            <p>Taxes and Additional Charges: You are responsible for any taxes or additional charges associated with your use of Night District's services, as required by applicable laws and regulations.</p>
            <br />
            <p>Invoice and Receipts: You will receive an invoice or receipt for each payment made to Night District. These documents will be provided to you electronically via email or can be accessed through your Night District account.</p>
            <br />
            <p>Disputes and Chargebacks: If you believe there is an error or discrepancy with any billing transaction, please contact <EmailLink color="#000" /> to resolve the matter. Chargebacks or disputes initiated without contacting our support team may result in the suspension or termination of your account.</p>
            <br />
            <p>By subscribing to Night District, you acknowledge that you have read, understood, and agree to these Payment and Billing terms. If you have any questions or concerns about your subscription or billing, please contact <EmailLink color="#000" />.</p>
            <br />

            <h2><strong>Governing Law</strong></h2>
            <p>The Terms of Service ("Terms") for Night District shall be governed by and construed in accordance with the laws of the state of Kentucky, United States, without regard to its conflict of law principles.</p>
            <br />
            <p>In the event of any disputes, claims, or legal proceedings arising from or related to the use of Night District's services, you agree to submit to the exclusive jurisdiction of the courts located in Kentucky. This choice of jurisdiction is intended to facilitate a fair and efficient resolution of any issues that may arise between you and Night District. By accessing or using Night District's services, you consent to the application of the laws of Kentucky to these Terms and any disputes that may arise. If any part of these Terms is found to be unenforceable or invalid by a court of competent jurisdiction, the remaining provisions shall remain in full force and effect.</p>
            <br />
            <p>This choice of governing law and jurisdiction is designed to provide a clear and predictable legal framework for the resolution of disputes and to promote a consistent interpretation of these Terms.</p>
            <br />

            <h2><strong>Modifications and Updates</strong></h2>
            <p>We're continuously working to enhance our services and features, and we may update these Terms of Service from time to time. Rest assured that any modifications will be communicated to you via in-app or website communication, and your continued use of the app after such changes will indicate your acceptance of the updated terms.</p>
            <br />

            <h2><strong>Termination of Accounts</strong></h2>
            <p>Night District reserves the right to suspend or terminate your account if you fail to comply with our Terms of Service or engage in any behavior that may be harmful to the community or the app. Night District also reserves the right to suspend or terminate the accounts of users who repeatedly infringe on the intellectual property rights of others or violate these Intellectual Property guidelines.</p>
            <br />
            
            <h2><strong>Dispute Resolution</strong></h2>
            <p>In the event of any disputes, claims, or controversies arising out of or relating to these Terms or the use of Night District's services, both you and Night District agree to make reasonable efforts to resolve the matter through good-faith negotiation and communication.</p>
            <br />
            <p>Informal Resolution: If you have any concerns or disputes regarding your use of Night District's services, we encourage you to first contact our customer support team at [contact email or support center]. We will diligently work with you to address and resolve your concerns in a fair and timely manner.</p>
            <br />
            <p>Mediation: If the matter remains unresolved through informal negotiation, both parties agree to consider non-binding mediation as an alternative dispute resolution method. The mediator shall be selected jointly, and the mediation shall be conducted in accordance with the rules and procedures of the mediation service or organization as agreed upon by both parties.</p>
            <br />
            <p>Arbitration: If resolution through mediation is not successful, any unresolved disputes, claims, or controversies shall be settled by binding arbitration in accordance with the rules and procedures of the American Arbitration Association (AAA), and judgment upon the award rendered by the arbitrator(s) may be entered in any court having jurisdiction thereof.</p>
            <br />
            <p>Waiver of Class Action: You and Night District waive any right to participate in a class or representative action in arbitration or in any court. You also agree that you have the right to opt-out of arbitration within 30 days of the date you first agreed to these Terms by providing written notice to <EmailLink color="#000" />.</p>
            <br />
            <p>Judicial Proceedings: Notwithstanding the above, either party may seek injunctive or other equitable relief to protect its intellectual property rights, privacy, or confidential information, or to prevent unauthorized access or use of Night District's services.</p>
            <br />
            <p>Applicable Law: This Dispute Resolution section shall be governed by and interpreted in accordance with the laws of the state of Kentucky, United States, without regard to its conflict of law principles.</p>
        </div>
          </Box>
        </Box>
      
    </>
  )
}



