import { Box, Button, Checkbox, Container, Img, Input, Link, Stack, useToast } from '@chakra-ui/react';
import React from 'react';
import { useLocation } from 'react-router-dom';
import { HeadFootEnabler } from '../../../utilities/HeadFootEnabler.js';
import Signupimg from '../../../assets/images/Banner/signup.jpg';
import CustomHeading from '../../../components/Website/Headings/CustomHeading.js';
import logo from '../../../assets/images/Banner/signlogo.png';
import { useEffect } from 'react';
import CustomPara from '../../../components/Website/Paragraph/CustomPara.js';
import { Link as ReactLink } from 'react-router-dom';
import { useState } from 'react';
import { POST, SetAccessToken } from '../../../utilities/ApiProvider.js';
import { useNavigate } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import { loadUser, updateColor, updateUser, } from '../../../reducers/useReducers.js';
//import { baseUrl } from '../../../utilities/Config.js';
import { SocialSignIn } from '../../../components/Website/External/SocialSignIn.js';

export default function Index() {
	const user = useSelector(state => state.value);
	const location = useLocation();
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const [remember, setRemember] = useState(false);

	const [show, setShow] = React.useState(false);
	const handleClick = () => setShow(!show);

	useEffect(() => {
		HeadFootEnabler(location);
	}, [location]);

	const signupstyle = {
		outline: '1px solid #fff',
		py: '25px',
		bg: '#271623b5',
		color: '#fff',
	};

	const toast = useToast();
	const [isLoading, setisLoading] = useState(false);
	const [Fields, setFields] = useState({
		username: '',
		password: '',
		role: "barowner",
		fcm: ""
	});

	const submitForm = async () => {
		try {
			setisLoading(true);
			const formData = new FormData();

			if (Fields.username === '' && Fields.password === '') {
				toast({
					status: 'error',
					title: 'Please fill in all the fields to proceed further.',
					duration: 7000,
					isClosable: true,
					position: 'bottom-left',
				});
				setisLoading(false);
				return;
			}

			formData.append('action', 'CONTACT');

			formData.append('username', Fields.username);
			formData.append('password', Fields.password);

			var response = await POST(`users/login`, Fields);

			if (response.status === 200) {
				if (remember) {
					localStorage.setItem('userCreds', JSON.stringify(Fields));
				}
				dispatch(loadUser(response.data));
				if (response.data.barDetails) {
					dispatch(updateColor(response.data.barDetails.color));
				}
				//
				localStorage.setItem('userCreds', JSON.stringify(response.data));
			}
			console.log(response)

			if (response?.status === 200 && response.data?.barInfo === null) {
				navigate('/dashboard/Plan');
			} else {
				SetAccessToken(response.data.verificationToken);
				console.log(response.data.verificationToken);
				navigate('/dashboard');
			}
			toast({
				description: response.message,
				status: response?.status > 299 ? 'error' : response.status < 300 ? 'success' : response?.status,
				isClosable: true,
				position: 'bottom-left',
				duration: 2500,
			});

			// setFields({
			//	 username: '',
			//	 password: '',
			// });

			setisLoading(false);
		} catch (err) {
			console.log(err);
			toast({
				description: err.message,
				status: 'error',
				isClosable: true,
				position: 'bottom-left',
				duration: 2500,
			});
		}
	};

	const lnk = {
		color: 'pHeading.100',
	};

	useEffect(() => {
		let creds = localStorage.getItem('userCreds');
		console.log(creds)
		if (creds) {
			setFields(JSON.parse(creds));
		} else {
			setFields({
				username: '',
				password: '',
				role: "barowner",
				fcm: ""
			});
		}

	}, [user]);

	useEffect(() => {
		let existingParsedUser = JSON.parse(localStorage.getItem("userCreds"));
		console.log(existingParsedUser)
		if (existingParsedUser) {
			if (existingParsedUser?.barInfo !== null || existingParsedUser?.barInfo !== undefined) {
				dispatch(loadUser(existingParsedUser));
				navigate('/dashboard')
			} else if (existingParsedUser?.barInfo === null || existingParsedUser?.barInfo === undefined) {
				navigate('/dashboard/Plan')
			} else {
				return;
			}
		}
	}, [])


	return (
		<>
			<Stack
				backgroundRepeat={'no-repeat'}
				backgroundImage={Signupimg}
				py={'48'}
			>
				<Container maxW={'6xl'}>
					<Stack mb={'12'}>
						<Img margin={'auto'} mb={'4'} w={'150px'} src={logo} />
						<CustomHeading color={'#fff'}>Welcome Back</CustomHeading>
						<CustomPara textAlign={'center'}>Enter your details</CustomPara>
					</Stack>
					<Stack
						mb={'8'}
						flexWrap={'wrap'}
						spacing={'0'}
						direction={'row'}
						gap={'6'}
						px={{ base: 'none', lg: '28px' }}
						justifyContent={'space-between'}
					>
						<Input
							sx={signupstyle}
							width={{ base: '100%', lg: '48%' }}
							placeholder={'Email Address'}
							type="email"
							_placeholder={{ color: '#fff' }}
							value={Fields?.username}
							onChange={e => {
								setFields({
									...Fields,
									username: e.target.value,
								});
							}}
						/>
						<Input
							sx={signupstyle}
							placeholder={'Password'}
							width={{ base: '100%', lg: '48%' }}
							type="password"
							_placeholder={{ color: '#fff' }}
							value={Fields?.password}
							onChange={e => {
								setFields({
									...Fields,
									password: e.target.value,
								});
							}}
						/>
						<Box display={"flex"} width={"100%"} justifyContent={{ base: "center", lg: "space" }} flexDirection={{ base: "column", lg: "row" }}>
							<Checkbox
								color={'#fff'}
								colorScheme="green"
								onChange={e => setRemember(e.target.checked)}
								defaultValue={remember}
							>
								Remember Password
							</Checkbox>
							<Link
								w={{ base: "100%", lg: "48%" }}
								color={'#fff'}
								as={ReactLink}
								to={'/dashboard/forgetPassword'}
							>
								Forgot Password?
							</Link>
						</Box>
					</Stack>
					<Stack mb={'12'}>
						<Box mb={'4'} textAlign={'center'}>
							<Button
								onClick={() => submitForm()}
								bgColor={'#dc0b9b'}
								w={{ base: "100%", lg: "48%" }}
								color={'#fff'}
								borderRadius={6}
								fontWeight={'600'}
								margin={'auto'}
								py={6}
								px={'12'}
								fontSize={'17px'}
								border={'2px solid #fff'}
								borderColor={'#dc0b9b'}
								_hover={{
									bgColor: 'transparent',
									color: '#fff',
								}}
								isLoading={isLoading}
							>
								Login
							</Button>
						</Box>
						<Box textAlign={'center'}>
							<Button
								as={ReactLink}
								to={'/dashboard/signup'}
								bgColor={'transparent'}
								w={{ base: "100%", lg: "48%" }}
								color={'#fff'}
								borderRadius={6}
								fontWeight={'600'}
								margin={'auto'}
								py={6}
								px={'12'}
								fontSize={'17px'}
								border={'2px solid #fff'}
								borderColor={'#dc0b9b'}
								_hover={{
									bgColor: 'transparent',
									color: '#fff',
								}}
							>
								SignUp
							</Button>
						</Box>
					</Stack>
					<SocialSignIn />
				</Container>
			</Stack>
		</>
	);
}
