import {
  Accordion,
  AccordionButton,
  AccordionItem,
  AccordionPanel,
  Box,
  Container,
  FormControl,
  FormLabel,
  Img,
  Input,
  Stack,
  Switch,
  Link,
  Text,
  Button,
  useDisclosure,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
} from '@chakra-ui/react';
import React from 'react';
import { useEffect } from 'react';
import { Navigate, useLocation, useNavigate } from 'react-router-dom';
import { HeadFootEnabler } from '../../../utilities/HeadFootEnabler';
import Signupimg from '../../../assets/images/Banner/signup.jpg';
import CustomHeading from '../../../components/Website/Headings/CustomHeading';
import CustomPara from '../../../components/Website/Paragraph/CustomPara';
import logo from '../../../assets/images/Banner/signlogo.png';
import menuimg from '../../../assets/images/menu/menu1.jpg';
import { AddIcon, MinusIcon } from '@chakra-ui/icons';
import { AiOutlinePlusCircle } from 'react-icons/ai';
import { Icon } from '@chakra-ui/icons';
import { Link as ReactLink } from 'react-router-dom';
import { imgUrl } from '../../../utilities/Config';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { addingMenu } from '../../../reducers/useReducers';
import { POST } from '../../../utilities/ApiProvider';

export default function Addfirstmenu(props) {
  const location = useLocation();

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const user = useSelector(state => state?.value);
  const currentMenu = useSelector(state => state?.addingMenu);

  const [menuFormat, setMenuFormat] = useState(null);

  useEffect(() => {
    setMenuFormat(location?.state);
  }, [location])


  useEffect(() => {
    if (!user) {
      navigate('/dashboard/login');
    }
  }, [user]);

  useEffect(() => {
    HeadFootEnabler(location);
  }, [location]);

  const updateVariationPrice = (itemId, variantId, newPrice) => {
    const updatedMenu = menuFormat?.menu.map((menuItem) => {
      if (menuItem.superItem === itemId) {
        const updatedVariation = menuItem.variation.map((v) => {
          if (v.variant === variantId) {
            return { ...v, price: newPrice };
          }
          return v;
        });
        return { ...menuItem, variation: updatedVariation };
      }
      return menuItem;
    });
    setMenuFormat({ menu: updatedMenu });
  };

  const finalizeMenu = async () => {
    console.log(menuFormat, 'FINAL FORMAT');
    let response = await POST('bar/Item', menuFormat, { authorization: `Bearer ${user?.verificationToken}` });
    console.log(response);
    if (response?.status === 'success' || response?.message === 'success') {
      onOpen();
    }
  }


  return (
    <>
      <Stack
        backgroundRepeat={'no-repeat'}
        backgroundSize={'cover'}
        backgroundImage={Signupimg}
        minH={'100vh'}
        py={'32'}
      >
        <Container maxW={'6xl'}>
          <Stack>
            <Stack>
              <Img margin={'auto'} mb={'4'} w={'150px'} src={logo} />
              <CustomHeading color={'#fff'}>Add Your Prices</CustomHeading>
              <CustomPara textAlign={'center'}>Enter your details</CustomPara>
            </Stack>
            <Stack>
              <Accordion allowMultiple>
                <AccordionItem
                  bgColor={'dashbg.100'}
                  marginBottom={{ base: '5px', md: '15px' }}
                >
                  {({ isExpanded }) => (
                    <Stack
                      className="chu"
                      border={
                        isExpanded ? '2px solid #f79e22' : '2px solid #e1e1e1'
                      }
                    >
                      <h2>
                        <AccordionButton
                          fontSize={'16px'}
                          fontWeight={'600'}
                          color={'primaryText.300'}
                          padding={'15px 15px'}
                          _hover={{
                            bgColor: '#fff',
                          }}
                        >
                          <Box flex="1" textAlign="left">
                            <Text fontSize={{ base: '12px', md: '13px' }}>
                              {/* {location?.state?.parent ?? ''} */}
                            </Text>
                            <Text fontSize={{ base: '12px', md: '16px' }}>
                              {/* {location?.state?.child ?? ''} */}
                            </Text>
                          </Box>
                          {isExpanded ? (
                            <MinusIcon fontSize="12px" />
                          ) : (
                            <AddIcon fontSize="12px" />
                          )}
                        </AccordionButton>
                      </h2>
                      <AccordionPanel
                        pb={4}
                        color={'primaryText.200'}
                        fontSize={'14px'}
                      >
                        <Stack gap={'6'}>
                          <Stack>
                            <FormControl position={'relative'}>
                              <FormLabel>Autofill price per 1.5oz</FormLabel>
                              <Input
                                size="lg"
                                color={'#fff'}
                                outline={'1px solid #fff'}
                                type="email"
                              />
                              <Switch
                                right={'10px'}
                                bottom={'15px'}
                                margin={'auto'}
                                position={'absolute'}
                                size="md"
                                colorScheme="green"
                              />
                            </FormControl>
                          </Stack>
                          <Stack
                            direction={'row'}
                            flexWrap={'wrap'}
                            gap={'2'}
                            spacing={'0'}
                          >
                            {menuFormat?.menu?.map((menu, index) => {
                              return (
                                <Stack
                                  bg={'#2c2a2a'}
                                  boxShadow="base"
                                  p="6"
                                  rounded="md"
                                  key={index}
                                >
                                  <Stack>
                                    {/* <Img
                                      height={'150px'}
                                      borderRadius={'6'}
                                      src={menu?.cat_img ?? ''}
                                    /> */}
                                    <CustomPara marginBottom={'0'}>
                                      {menu?.title ?? ""}
                                    </CustomPara>
                                  </Stack>
                                  <Stack gap={'2'}>
                                    <FormControl>
                                      {
                                        menu?.variation?.map((val, i) =>
                                          <Stack mb={'10px !important'}>
                                            <FormLabel>{i === 0 ? 'Shot' : 'Neat/Rocks'}</FormLabel>
                                            <Input
                                              outline={'1px solid #fff'}
                                              type="text"
                                              value={val?.price}
                                              onChange={(e) => updateVariationPrice(menu?.superItem, val?.variant, e.target.value)}
                                            />
                                          </Stack>
                                        )
                                      }
                                    </FormControl>
                                    {/* <FormControl>
                                      <FormLabel>1.50z</FormLabel>
                                      <Input
                                        outline={'1px solid #fff'}
                                        type="email"
                                      />
                                    </FormControl> */}
                                    {/* <FormControl>
                                      <FormLabel>Shot</FormLabel>
                                      <Input
                                        outline={'1px solid #fff'}
                                        type="text"
                                        value={menu?.variation[0]['price']}
                                        onChange={(e) => updateVariationPrice(menu?.item, menu?.variation[0]['variant'], e.target.value)}
                                      />
                                    </FormControl>
                                    <FormControl>
                                      <FormLabel>Neat/Rocks</FormLabel>
                                      <Input
                                        outline={'1px solid #fff'}
                                        type="text"
                                        value={menu?.variation[1]['price']}
                                      />
                                    </FormControl> */}
                                  </Stack>
                                </Stack>
                              );
                            })}

                            {/* <Stack
                              bg={'#2c2a2a'}
                              boxShadow="base"
                              p="6"
                              rounded="md"
                            >
                              <Stack
                                height={'140px'}
                                borderRadius={'6'}
                                border={'1px dashed #fff'}
                                display={'flex'}
                                alignItems={'center'}
                                textAlign={'center'}
                                justifyContent={'center'}
                              >
                                <Link as={ReactLink} to={'/'}>
                                  <Icon
                                    fontSize={'40px'}
                                    color={'#fff'}
                                    as={AiOutlinePlusCircle}
                                  />
                                </Link>
                              </Stack>
                              <Stack gap={'2'}>
                                <FormControl>
                                  <FormLabel>1.50z</FormLabel>
                                  <Input
                                    outline={'1px solid #fff'}
                                    type="email"
                                  />
                                </FormControl>
                                <FormControl>
                                  <FormLabel>Shot</FormLabel>
                                  <Input
                                    outline={'1px solid #fff'}
                                    type="email"
                                  />
                                </FormControl>
                                <FormControl>
                                  <FormLabel>Neat/Rocks</FormLabel>
                                  <Input
                                    outline={'1px solid #fff'}
                                    type="email"
                                  />
                                </FormControl>
                              </Stack>
                            </Stack> */}
                          </Stack>
                        </Stack>
                      </AccordionPanel>
                    </Stack>
                  )}
                </AccordionItem>
              </Accordion>
              <Button
                onClick={() => { finalizeMenu() }}
              >Add Menu</Button>
            </Stack>
          </Stack>
        </Container>
        <Modal isOpen={isOpen} onClose={onClose}>
          <ModalOverlay />
          <ModalContent>
            <ModalHeader>Menu Added Successfully!</ModalHeader>
            <ModalCloseButton />
            <ModalBody>
            </ModalBody>
            <Text px={'6'}>Would you like to add more items to your menu?</Text>
            <ModalFooter>
              <Button colorScheme='blue' onClick={() => navigate('/dashboard/menu/choosecategory')}>
                Add Items
              </Button>
              <Button variant='ghost' onClick={() => navigate('/dashboard')}>Continue</Button>
            </ModalFooter>
          </ModalContent>
        </Modal>
      </Stack>
    </>
  );
}
