import React from 'react';
import {
    Box,
} from '@chakra-ui/react';

export default function Mockup({ mockupImage }) {
    return (
        <Box
            flex={1}
            display={'flex'}
            justifyContent={'center'}
        >
            <div style={{ width: '475px', height: '475px' }}>
                <img src={mockupImage} alt="Mockup" style={{ width: '100%', height: '100%' }} />
            </div>
        </Box>
    );
}
