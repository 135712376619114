import React from 'react';
import {
    Box,
    Heading,
    Text,
} from '@chakra-ui/react';

export default function Caption({ captionHeading, captionPara, captionAlign }) {
    return (
        <Box
            flex={1}
        >
            <Heading
                lineHeight={1.4}
                fontStyle={'bold'}
                fontSize={{
                    base: '36px',
                    md: '50px',
                    xl: '45px',
                    '2xl': '54px',
                }}
                pb={{ base: '4', lg: '8' }}
                color={'white'}
                fontFamily={'Avenir'}
                maxW="800px"
                textAlign={{ base: 'center', md: captionAlign ? 'left' : 'right' }}
                textShadow="0px 0px 8px #fff"
            >
                {captionHeading}
            </Heading>

            <Text
                fontSize={{ base: '14px', md: '14px', lg: '18px' }}
                fontWeight={'bold'}
                color={'white'}
                lineHeight={{ md: '30px', base: '23px' }}
                fontFamily={'Avenir'}
                textAlign={{ base: 'center', md: captionAlign ? 'left' : 'right' }}
            >
                {captionPara}
            </Text>
        </Box>
    );
}